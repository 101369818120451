import { api } from "./api";

export const testService = api.injectEndpoints({
    endpoints: (build) => ({
        getTestsList: build.query({
            query: ({ pageSize }) => `surveys/?pageSize=${pageSize}`,
        }),
        getTestsId: build.query({
            query: ({ id }) => `surveys/${id}`,
        }),
        postTest: build.mutation({
            query({ body, id }) {
                return {
                    url: `surveys/${id}`,
                    method: "POST",
                    body,
                };
            },
        }),
    }),
});

export const {
    useGetTestsListQuery,
    useGetTestsIdQuery,
    usePostTestMutation,
} = testService;
