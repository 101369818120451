import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Checkbox,
    Typography,
    Box,
    TextField,
} from "@mui/material";

const MultpleChoise = ({
    name,
    variants,
    index,
    register,
    questionId,
}) => {
    return (
        <FormControl sx={{ mb: 2 }}>
            <FormLabel>
                <Typography
                    color="#343434"
                    sx={{
                        fontSize: { sm: 20, xs: 16 },
                        fontWeight: 600,
                        mb: 1,
                    }}
                >
                    {name}
                </Typography>
            </FormLabel>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                    sx={{ display: "none" }}
                    {...register(`answers.${index}.q_id`)}
                    defaultValue={questionId}
                />
                {variants.map((item) => (
                    <FormControlLabel
                        key={item.a_id}
                        control={
                            <Checkbox
                                {...register(
                                    `answers.${index}.multiple_choice.${item.a_id}.a_id`
                                )}
                                value={item.a_id}
                            />
                        }
                        label={
                            <Typography
                                color="#343434"
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                }}
                            >
                                {item.name}
                            </Typography>
                        }
                    />
                ))}
            </Box>
        </FormControl>
    );
};

export default MultpleChoise;
