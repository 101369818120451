import { Box, Container, Typography } from "@mui/material";

import {
    rabota,
    rostrud,
    gosuslugi,
    yakutiakom,
} from "../../../../static/img";

const UsefulLinksScreen = () => {
    const data = [
        {
            img: rostrud,
            link: "https://rostrud.gov.ru/",
        },
        {
            img: yakutiakom,
            link: "https://gkzn.sakha.gov.ru/",
        },
        {
            img: gosuslugi,
            link: "https://gosuslugi.ru/",
        },
        {
            img: rabota,
            link: "https://trudvsem.ru/",
        },
    ];

    return (
        <Box
            sx={{
                mt: {
                    md: 19,
                    xs: 8,
                },
                display: {
                    xs: "none",
                    sm: "block",
                },
            }}
        >
            <Container>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: {
                            xs: 18,
                            md: 28,
                        },
                        mb: 2,
                        textTransform: {
                            md: "none",
                            xs: "uppercase",
                        },
                    }}
                >
                    Полезные ссылки
                </Typography>
            </Container>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "white",
                    width: "100%",
                    pt: 2,
                    pb: 2,
                    mt: 3,
                    gap: 10,
                }}
            >
                {data.map((item, index) => (
                    <img
                        src={item.img}
                        key={index}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                            (window.location.href = item.link)
                        }
                    />
                ))}
            </Box>
        </Box>
    );
};

export default UsefulLinksScreen;
