import React from "react";
import {
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    Box,
    IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import { ModalsSlice } from "../../reducer/ModalsSlice";
import { ChatBotSlice } from "../../reducer/ChatBotSlice";
import { ModalLayout } from "../../layouts";
import ROUTES from "../../routes";

const GlobalQuestionModal = () => {
    const { globalQuestionModalOpen } = useSelector(
        (state) => state.ModalsSlice
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleGlobalQuestionModalOpen, handleFeedBackModal } =
        ModalsSlice.actions;

    const { handleShowChatBot } = ChatBotSlice.actions;

    const openFeedBackModal = () => {
        dispatch(handleGlobalQuestionModalOpen(false));
        dispatch(handleFeedBackModal(true));
    };

    const navigation = () => {
        dispatch(handleGlobalQuestionModalOpen(false));
        navigate(ROUTES.FAQ_PAGE);
    };

    const onClose = () => {
        dispatch(handleGlobalQuestionModalOpen(false));
    };

    const openChatBot = () => {
        dispatch(handleGlobalQuestionModalOpen(false));
        dispatch(handleShowChatBot(true));
    };

    return (
        <ModalLayout
            open={globalQuestionModalOpen}
            onClose={onClose}
            maxWidth="xs"
        >
            <Box
                sx={{
                    p: {
                        sm: 2,
                        xs: 0,
                    },
                    mt: {
                        xs: 11,
                        sm: 0,
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: {
                                    sm: 28,
                                    xs: 22,
                                },
                                fontWeight: 500,
                                textAlign: "center",
                            }}
                            color="primary.main"
                        >
                            ЕСТЬ ВОПРОСЫ?
                        </Typography>
                        <IconButton
                            sx={{ right: 20, position: "absolute" }}
                            onClick={onClose}
                        >
                            <CloseIcon
                                fontSize="large"
                                sx={{ color: "#1B4E9B" }}
                            />
                        </IconButton>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 500,
                            textAlign: "center",
                            mt: 1.6,
                        }}
                    >
                        Задайте свой вопрос любым удобным
                        <br /> для себя способом
                    </Typography>
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        mt: 3,
                    }}
                >
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={openChatBot}
                    >
                        Чат-бот
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={navigation}
                    >
                        Вопрос / Ответ
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={openFeedBackModal}
                    >
                        Написать нам
                    </Button>
                </DialogContent>
            </Box>
        </ModalLayout>
    );
};

export default GlobalQuestionModal;
