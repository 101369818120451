import { Box, Grid } from "@mui/material";

import { InteractiveMapSideBar } from "../../components";

const InteractiveMapLayout = ({ children }) => {
    return (
        <div>
            <Grid container spacing={2} sx={{ mt: 2.5 }}>
                <Grid
                    item
                    xl={8}
                    lg={8}
                    md={7}
                    sm={12}
                    xs={12}
                    sx={{
                        height: {
                            xs: 380,
                            sm: 800,
                            md: 850,
                        },
                    }}
                >
                    <Box
                        sx={{
                            bgcolor: "white",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            filter: "drop-shadow(1px 2px 8px rgba(0, 0, 0, 0.15))",
                            borderRadius: 4,
                        }}
                    >
                        {children}
                    </Box>
                </Grid>
                <Grid
                    item
                    xl={4}
                    lg={4}
                    md={5}
                    sm={12}
                    xs={12}
                    sx={{
                        height: {
                            xs: 850,
                            sm: 500,
                            md: 850,
                        },
                    }}
                >
                    <InteractiveMapSideBar />
                </Grid>
            </Grid>
        </div>
    );
};

export default InteractiveMapLayout;
