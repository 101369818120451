import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import ROUTES from "../../routes";
import { formattedDate } from "../../lib";

const Card = styled(Box)(() => ({
    width: 215,
    height: "100%",
    marginRight: 10,
    borderRadius: 12,
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 5,
    position: "relative",
    border: "1px solid #CDCDCD",
    cursor: "pointer",
}));

const SliderNewsCard = ({ date, img, description, id, views }) => {
    const navigate = useNavigate();

    const navigateDetail = () => {
        navigate(`${ROUTES.NEWS_DETAIL_PAGE}/${id}`);
    };
    return (
        <Card onClick={navigateDetail}>
            <img
                alt=""
                src={img}
                style={{
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                    height: 160,
                    width: "100%",
                    objectFit: "cover",
                }}
            />
            <Box
                style={{
                    marginTop: 10,
                    marginLeft: 8,
                    marginRight: 8,
                    marginBottom: 8,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: 105,
                }}
            >
                <Box sx={{ overflow: "hidden" }}>
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 500,
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            display: "-webkit-box",
                        }}
                        color="customColors.lightDart"
                    >
                        {description}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        color="customColors.grey"
                        sx={{ fontSize: 14 }}
                    >
                        {formattedDate(date)}
                    </Typography>
                    {/* <Box
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <RemoveRedEyeIcon
                            fontSize="14px"
                            sx={{
                                color: "customColors.grey",
                                mr: 0.7,
                                mt: "-1px",
                            }}
                        />
                        <Typography
                            color="customColors.grey"
                            sx={{ fontSize: 14 }}
                        >
                            {views}
                        </Typography>
                    </Box> */}
                </Box>
            </Box>
        </Card>
    );
};

export default SliderNewsCard;
