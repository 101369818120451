import * as React from "react";
import { DateRange } from "react-date-range";
import { ru } from "date-fns/esm/locale";

const MyCalendar = ({ setDate, date }) => {
    const onChangeDate = (item) => {
        setDate([item.selection]);
    };

    return (
        <DateRange
            ranges={date}
            locale={ru}
            onChange={onChangeDate}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
        />
    );
};

export default MyCalendar;
