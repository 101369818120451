import { Box, Grid } from "@mui/material";

import { PartnersCard } from "../../../../components";

const PartnersBody = ({ partnersData }) => {
    return (
        <Box>
            <Grid container spacing={2}>
                {partnersData?.results.map((item, index) => (
                    <Grid
                        item
                        xl={2.4}
                        lg={2.4}
                        md={3}
                        sm={4}
                        xs={6}
                        key={index}
                    >
                        <PartnersCard
                            type="page"
                            img={item.banner}
                            description={item.name}
                            id={item.id}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default PartnersBody;
