import * as React from "react";

const QuestionMark = (props) => (
    <svg
        width={18}
        height={30}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6 25.5h4.5V30H6v-4.5ZM9 0c8.025.33 11.52 8.43 6.75 14.505-1.245 1.5-3.255 2.49-4.245 3.75C10.5 19.5 10.5 21 10.5 22.5H6c0-2.505 0-4.62 1.005-6.12.99-1.5 3-2.385 4.245-3.375C14.88 9.645 13.98 4.89 9 4.5A4.5 4.5 0 0 0 4.5 9H0a9 9 0 0 1 9-9Z"
            fill="#fff"
        />
    </svg>
);

export default QuestionMark;
