import { Box, Typography } from "@mui/material";

import ROUTES from "../../../../../routes";
import { CommonCard } from "../../../../../components";

const LastEvent = ({ data }) => {
    console.log("eventCalendarFullData", data);

    return (
        <Box>
            <Typography
                sx={{ fontSize: 20, fontWeight: 500 }}
                color="primary.main"
            >
                Последнее мероприятие
            </Typography>
            {data?.results.slice(0, 1).map((item, index) => (
                <CommonCard
                    key={index}
                    short_desc={item.short_desc}
                    image={item.image}
                    name={item.name}
                    pub_date={item.pub_date}
                    id={item.id}
                    route={ROUTES.EVENT_CALENDAR_DETAIL_PAGE}
                />
            ))}
        </Box>
    );
};

export default LastEvent;
