import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

const HomeBody = () => {
    return (
        <Box
            sx={{
                bgcolor: "bg.main",
                overflow: "hidden",
                pb: {
                    md: 31,
                    xs: 11,
                },
                minHeight: 800,
            }}
        >
            <Outlet />
        </Box>
    );
};

export default HomeBody;
