const informationList = [
    {
        label: "Директор:",
        value: "Олесова Роза Семеновна",
    },
    {
        label: "1 заместитель директора:",
        value: "Соловьев Игорь Иннокентьевич",
    },
    {
        label: "Заместитель директора:",
        value: "Хабарова Марианна Валериевна",
    },
    {
        label: "Адрес:",
        value: "677000, Республика Саха (Якутия), город Якутск, ул. Петра Алексеева, дом 6, корпус 1",
    },
    {
        label: "Телефон:",
        value: "8(4112) 31-92-39 - приемная",
    },
    // {
    //     label: "Контактный телефон по вопросам трудоустройства:",
    //     value: "(4112) 508-801, (4112) 31-84-55",
    // },
    // {
    //     label: "Контактное лицо по вопросам трудоустройства:",
    //     value: "Хабарова Марианна Валериевна",
    // },
    {
        label: "Электронная почта:",
        value: "Yakutia@sakhaczn.ru",
    },
    {
        label: "Адрес сайта:",
        value: "https://работавякутии.рф",
    },
];

export default informationList;
