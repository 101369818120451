import { Box } from "@mui/material";

import {
    VideoPlayer,
    DescriptionScreen,
    InformationScreen,
} from "../../../../components";

const EmployersDetailBody = ({ employersDataId }) => {
    return (
        <Box>
            {employersDataId?.video && (
                <VideoPlayer url={employersDataId?.video} />
            )}
            <Box sx={{ bgcolor: "white", p: 2, mt: 2 }}>
                <DescriptionScreen data={employersDataId} />
                <InformationScreen data={employersDataId} />
            </Box>
        </Box>
    );
};

export default EmployersDetailBody;
