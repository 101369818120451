import { api } from "./api";

export const filialService = api.injectEndpoints({
    endpoints: (build) => ({
        getFilialData: build.query({
            query: () => `branches/districts/?page=${1}&pageSize=40`,
        }),
    }),
});

export const { useGetFilialDataQuery } = filialService;
