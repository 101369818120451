import { Box, Button } from "@mui/material";

const tabMenuItemList = [
    {
        label: "Тестирование",
        value: 1,
    },
];

const TabMenu = ({ tabValue, handleTabNavigation }) => {
    return (
        <Box
            sx={{
                display: "flex",
                gap: 1.8,
            }}
        >
            {tabMenuItemList.map((item, index) => (
                <Button
                    key={index}
                    color="secondary"
                    variant={
                        tabValue === item.value
                            ? "contained"
                            : "outlined"
                    }
                    onClick={() => handleTabNavigation(item.value)}
                >
                    {item.label}
                </Button>
            ))}
        </Box>
    );
};

export default TabMenu;
