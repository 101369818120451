import { api } from "./api";

export const contestsService = api.injectEndpoints({
    endpoints: (build) => ({
        getContestsData: build.query({
            query: ({ currentPage }) =>
                `contests/?page=${currentPage}`,
        }),
        getContestsDataId: build.query({
            query: ({ id }) => `contests/${id}`,
        }),
    }),
});

export const { useGetContestsDataIdQuery, useGetContestsDataQuery } =
    contestsService;
