import { api } from "./api";

export const citizensService = api.injectEndpoints({
    endpoints: (build) => ({
        getCitizensData: build.query({
            query: ({ currentPage }) =>
                `services/user/?page=${currentPage}`,
        }),
        getCitizensDataId: build.query({
            query: ({ id }) => `services/user/${id}`,
        }),
    }),
});

export const { useGetCitizensDataIdQuery, useGetCitizensDataQuery } =
    citizensService;
