import { api } from "./api";

export const minorsEmploymentService = api.injectEndpoints({
    endpoints: (build) => ({
        getMinorsList: build.query({
            query: () => `minors/`,
        }),
        getMinorsSection: build.query({
            query: () => `minors/sections/`,
        }),
    }),
});

export const {
    useGetMinorsListQuery,
    useGetMinorsSectionQuery,
} = minorsEmploymentService;
