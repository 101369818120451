import React from "react";
import {
    Box,
    Container,
    Tooltip,
    tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import VisibilityIcon from "@mui/icons-material/Visibility";

import menuList from "./menuList";
import { CustomMenuItem, DropDownMenu } from "..";

const HeaderContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

const MenuHeader = styled(Box)(() => ({
    height: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
}));

const LeftMenu = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const HomeHeader = () => {
    return (
        <Box
            sx={{
                bgcolor: "white",
                width: "100%",
            }}
        >
            <HeaderContainer>
                <MenuHeader>
                    <LeftMenu>
                        {menuList.map((item, index) => (
                            <Box key={index}>
                                {item.type === "menuItem" && (
                                    <CustomMenuItem
                                        path={item.path}
                                        sx={{
                                            marginRight: 1,
                                            color: "#828282",
                                        }}
                                        type="header"
                                    >
                                        {item.label}
                                    </CustomMenuItem>
                                )}
                                {item.type === "dropDown" && (
                                    <LightTooltip
                                        title={
                                            <DropDownMenu
                                                dropDownList={
                                                    item.dropDownList
                                                }
                                            />
                                        }
                                    >
                                        <div>
                                            <CustomMenuItem
                                                type="header"
                                                sx={{
                                                    marginRight: 1,
                                                    color: "#828282",
                                                }}
                                            >
                                                {item.label}
                                            </CustomMenuItem>
                                        </div>
                                    </LightTooltip>
                                )}
                            </Box>
                        ))}
                    </LeftMenu>

                    <div
                        onClick={() => {
                            document
                                .getElementById("specialButton")
                                .click();
                        }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CustomMenuItem
                            color="headerTheme.color"
                            type="header"
                            icon={
                                <VisibilityIcon
                                    fontSize="small"
                                    sx={{
                                        color: "customColors.grey",
                                    }}
                                />
                            }
                        >
                            Для слабовидящих
                        </CustomMenuItem>
                    </div>
                </MenuHeader>
            </HeaderContainer>
        </Box>
    );
};

export default HomeHeader;
