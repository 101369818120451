import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showInteractiveMapModal: false,
    regionData: {},
};

export const InteractiveMapSlice = createSlice({
    name: "InteractiveMapSlice",
    initialState,
    reducers: {
        handleOpenInteractiveMapModal(state, action) {
            state.showInteractiveMapModal = action.payload;
        },
        getRegionData(state, action) {
            state.regionData = action.payload;
        },
    },
});

export default InteractiveMapSlice.reducer;
