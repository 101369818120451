import { Box, Typography } from "@mui/material";

import {
    DescriptionScreen,
    ImgScreen,
    InformationScreen,
    ShareScreen,
    DocumentScreen,
} from "../../../../components";

const NewsDetailBody = ({ newsDataId }) => {
    console.log("newsData", newsDataId);

    return (
        <Box>
            <ImgScreen data={newsDataId} />
            <Box sx={{ bgcolor: "white", p: 2, mt: 2 }}>
                <DescriptionScreen data={newsDataId} />
                <InformationScreen data={newsDataId} />
                {newsDataId.document && (
                    <DocumentScreen data={newsDataId} />
                )}
                <ShareScreen data={newsDataId} />
            </Box>
        </Box>
    );
};

export default NewsDetailBody;
