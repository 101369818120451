import { Box } from "@mui/material";
import Slider from "react-slick";

import { SliderNewsCard, NewsSliderCardSkeletonLaoding } from "..";
import { SampleNextArrow, SamplePrevArrow } from "./arrows";

const NewsSlider = ({ newsData, generalLoading }) => {
    const settings = {
        dots: true,
        centerPadding: "0px",
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        variableWidth: true,
        swipeToSlide: true,
        autoplaySpeed: 2500,
        autoplay: true,
    };

    const SkeletonLoadingCount = 6;

    return (
        <Box>
            <Slider {...settings}>
                {generalLoading()
                    ? Array(SkeletonLoadingCount)
                          .fill(0)
                          .map((index) => (
                              <NewsSliderCardSkeletonLaoding
                                  key={index}
                              />
                          ))
                    : newsData?.results
                          .slice(0, 6)
                          .map((item, index) => (
                              <SliderNewsCard
                                  key={index}
                                  date={item.pub_date}
                                  description={item.short_desc}
                                  img={item.image}
                                  id={item.id}
                                  views={item.views}
                              />
                          ))}
            </Slider>
        </Box>
    );
};

export default NewsSlider;
