import {
    VideoPlayer,
    PageTitle,
    DescriptionScreen,
    DocumentScreen,
} from "../../../../components";

const EventCalendarDetailBody = ({ eventCalendarDataId }) => {
    return (
        <div>
            <PageTitle>{eventCalendarDataId.name}</PageTitle>
            {eventCalendarDataId.video && (
                <VideoPlayer url={eventCalendarDataId.video} />
            )}
            <DescriptionScreen data={eventCalendarDataId} />
            {eventCalendarDataId.document && (
                <DocumentScreen data={eventCalendarDataId} />
            )}
        </div>
    );
};

export default EventCalendarDetailBody;
