import { api } from "./api";

export const promoBlocksService = api.injectEndpoints({
    endpoints: (build) => ({
        getPromoBlocksList: build.query({
            query: () => `promo/`,
        }),
    }),
});

export const { useGetPromoBlocksListQuery } = promoBlocksService;
