import * as React from "react";

const OkIcon = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16.03 9.468c1.104 0 2.003.9 2.003 2.002 0 1.103-.9 2.002-2.002 2.002-1.102 0-2.002-.9-2.002-2.002-.029-1.102.87-2.002 2.002-2.002Z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0Zm.03 16.316a4.854 4.854 0 0 0 4.846-4.846 4.854 4.854 0 0 0-4.845-4.845c-2.698 0-4.874 2.176-4.874 4.845 0 2.67 2.176 4.845 4.874 4.845Zm1.945 3.974a9.17 9.17 0 0 0 2.814-1.16c.667-.406.87-1.306.435-1.973a1.413 1.413 0 0 0-1.973-.435 6.16 6.16 0 0 1-6.528 0 1.445 1.445 0 0 0-1.972.435c-.378.667-.175 1.538.493 1.944.87.551 1.828.928 2.814 1.16L11.36 22.96a1.418 1.418 0 0 0 0 2.002c.29.29.638.406 1.015.406.377 0 .726-.145 1.016-.406l2.669-2.67 2.67 2.67c.55.55 1.45.55 2.001 0a1.419 1.419 0 0 0 0-2.002l-2.756-2.67Z"
            fill="#fff"
        />
    </svg>
);

export default OkIcon;
