import Chatbot from "react-chatbot-kit";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import "./chatBot.css";
import config from "./config";
import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import { ChatBotSlice } from "../../reducer/ChatBotSlice";

const ChatBot = () => {
    const { handleShowChatBot } = ChatBotSlice.actions;

    const { showChatBot } = useSelector(
        (state) => state.ChatBotSlice
    );

    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(handleShowChatBot(false));
    };

    return (
        <Box
            sx={{
                position: "fixed",
                zIndex: 11,
                bottom: showChatBot ? 5 : 0,
                right: 5,
                width: {
                    sm: 465,
                    xs: 318,
                },
                height: showChatBot
                    ? {
                          xs: 485,
                          sm: 560,
                      }
                    : 0,
                transition: "all 700ms ease",
                display: "flex",
                flexDirection: {
                    xs: "column",
                    sm: "row",
                },
            }}
        >
            <Box
                onClick={onClose}
                sx={{
                    width: 48,
                    height: 48,
                    background: "#CDCDCD",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "25px",
                    marginRight: 2,
                    cursor: "pointer",
                    marginBottom: 1,
                }}
            >
                <CloseIcon />
            </Box>
            <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
                placeholderText="Напишите что нибудь..."
            />
        </Box>
    );
};

export default ChatBot;
