import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showMobileDrawer: false,
    showMobileDrawerSideMenu: false,
    sideMenuList: [],
};

export const MobileDrawerSlice = createSlice({
    name: "MobileDrawerSlice",
    initialState,
    reducers: {
        handleShowDrawer(state, action) {
            state.showMobileDrawer = action.payload;
        },
        handleShowMobileDrawerSideMenu(state, action) {
            state.showMobileDrawerSideMenu = action.payload;
        },
        getSideMenuList(state, action) {
            state.sideMenuList = action.payload;
        },
    },
});

export default MobileDrawerSlice.reducer;
