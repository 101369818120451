import { api } from "./api";

export const employersService = api.injectEndpoints({
    endpoints: (build) => ({
        getEmployersData: build.query({
            query: ({ currentPage }) =>
                `services/staff/?page=${currentPage}`,
        }),
        getEmployersDataId: build.query({
            query: ({ id }) => `services/staff/${id}`,
        }),
    }),
});

export const {
    useGetEmployersDataQuery,
    useGetEmployersDataIdQuery,
} = employersService;
