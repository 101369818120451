import { useState } from "react";
import { Box, TextField, Checkbox, Typography } from "@mui/material";
import { styled } from "@mui/system";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FeedBackTools from "./FeedBackTools";
import { MyButton } from "../..";

const Form = styled("form")(({ theme }) => ({
    padding: 10,
}));

const FeedBackForm = () => {
    const [disableButton, setDisableButton] = useState(false);

    const { onFinish, schema } = FeedBackTools();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onDisableButton = (value) => {
        setDisableButton(value);
    };

    return (
        <Form onSubmit={handleSubmit(onFinish)}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: {
                        xs: "column",
                        sm: "row",
                    },
                    gap: {
                        xs: 2,
                        sm: 0,
                    },
                }}
            >
                <TextField
                    label="ФИО"
                    placeholder="ФИО"
                    sx={{ width: "100%" }}
                    {...register("full_name")}
                    error={!!errors?.full_name}
                    helperText={errors?.full_name?.message}
                />
                <TextField
                    label="Телефон"
                    sx={{
                        ml: {
                            sm: 2,
                            xs: 0,
                        },
                        width: "100%",
                    }}
                    placeholder="7 (999) 999 99-99"
                    {...register("phone_number")}
                    type="number"
                    error={!!errors?.phone_number}
                    helperText={errors?.phone_number?.message}
                />
            </Box>
            <TextField
                rows={4}
                label="Сообщение"
                multiline
                sx={{ width: "100%", mt: 2 }}
                placeholder="Напишите сообщение..."
                {...register("message")}
                error={!!errors?.message}
                helperText={errors?.message?.message}
            />
            <Box
                sx={{
                    ml: "-10px",
                    display: "flex",
                    mt: 2,
                    alignItems: " center",
                }}
            >
                <Checkbox
                    sx={{ mr: 1 }}
                    checked={disableButton}
                    onChange={(e) =>
                        onDisableButton(e.target.checked)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                />
                <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                    Нажимая на кнопку, вы соглашаетесь с
                    <span
                        style={{
                            color: "#1B4E9B",
                            marginLeft: 5,
                            cursor: "pointer",
                        }}
                    >
                        <a
                            href="/docs/Policy_on_the_processing_of_personal_data_of_the_work_portal_yakutia.docx"
                            download
                        >
                            Политикой персональных данных
                        </a>
                    </span>
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: {
                        xs: "start",
                        sm: "cente",
                    },
                    mt: 2,
                    flexDirection: {
                        xs: "column-reverse",
                        sm: "row",
                    },
                    gap: {
                        sm: 0,
                        xs: 3,
                    },
                }}
            >
                <MyButton
                    type="submit"
                    disabled={disableButton ? false : true}
                    sx={{
                        width: {
                            xs: "100%",
                            sm: "max-content",
                        },
                    }}
                >
                    Отправить
                </MyButton>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: {
                            xs: "column",
                            sm: "row",
                        },
                        gap: {
                            xs: 2,
                            sm: 0,
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <LocalPhoneIcon
                            fontSize="medium"
                            sx={{ color: "primary.main" }}
                        />
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 500,
                                ml: 1,
                            }}
                        >
                            8(4112) 318-337
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            ml: {
                                xs: 0,
                                sm: 4,
                            },
                        }}
                    >
                        <EmailIcon
                            fontSize="medium"
                            sx={{ color: "primary.main" }}
                        />
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 500,
                                ml: 1,
                            }}
                        >
                            yakutia@sakhaczn.ru
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Form>
    );
};

export default FeedBackForm;
