import { Box, Typography } from "@mui/material";

const DocumentScreen = ({ data }) => {
    return (
        <Box
            sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
            }}
        >
            <Typography
                sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#828282",
                }}
            >
                Документ:
            </Typography>

            <a
                download
                href={data.document}
                className="underline text-[#1890FF]"
                style={{
                    fontSize: 16,
                    textDecoration: "none",
                }}
            >
                {decodeURI(
                    data.document.substring(
                        data.document.lastIndexOf("/") + 1
                    )
                )}
            </a>
        </Box>
    );
};

export default DocumentScreen;
