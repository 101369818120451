import {
    FormControl,
    FormControlLabel,
    RadioGroup,
    FormLabel,
    Radio,
    Typography,
    Box,
    TextField,
} from "@mui/material";

const OneChoise = ({
    name,
    variants,
    register,
    index,
    questionId,
}) => {
    return (
        <FormControl sx={{ mb: 2 }}>
            <FormLabel>
                <Typography
                    color="#343434"
                    sx={{
                        fontSize: {
                            sm: 20,
                            xs: 16,
                        },
                        fontWeight: 600,
                        mb: 1,
                    }}
                >
                    {name}
                </Typography>
            </FormLabel>
            <RadioGroup>
                {variants.map((item) => (
                    <Box key={item.a_id}>
                        <FormControlLabel
                            control={
                                <Radio
                                    {...register(
                                        `answers.${index}.a_id`
                                    )}
                                    value={item.a_id}
                                />
                            }
                            label={
                                <Typography
                                    color="#343434"
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                >
                                    {item.name}
                                </Typography>
                            }
                        />
                        <TextField
                            sx={{ display: "none" }}
                            {...register(`answers.${index}.q_id`)}
                            defaultValue={questionId}
                        />
                    </Box>
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default OneChoise;
