import { Grid } from "@mui/material";

import { useGetMinorsSectionQuery } from "../../services/MinorsEmploymentService";
import MinorsAccardion from "./components/MinorsAccardion.jsx";
import { CommonLoading } from "../../components";

const MinorsEmploymentPage = () => {
    const {
        data: minorsList,
        isLoading: isMinorsListLoading,
    } = useGetMinorsSectionQuery();

    if (isMinorsListLoading) {
        return <CommonLoading />;
    }

    return (
        <Grid container spacing={2}>
            {minorsList.results?.map((item, index) => (
                <Grid
                    item
                    key={index}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                >
                    <MinorsAccardion
                        name={item.name}
                        description={item.description}
                        documents={item.documents}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default MinorsEmploymentPage;
