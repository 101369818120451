import { Box } from "@mui/material";

import {
    VideoPlayer,
    DescriptionScreen,
    InformationScreen,
} from "../../../../components";

const CitizensDetailBody = ({ citizensDataId }) => {
    return (
        <Box>
            {citizensDataId?.video && (
                <VideoPlayer url={citizensDataId?.video} />
            )}
            <Box sx={{ bgcolor: "white", p: 2, mt: 2 }}>
                <DescriptionScreen data={citizensDataId} />
                <InformationScreen data={citizensDataId} />
            </Box>
        </Box>
    );
};

export default CitizensDetailBody;
