import * as React from "react";

const Vk = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.334 13h-1.167c-.334 0-.4.157-.4.33 0 .309.395 1.842 1.842 3.869.964 1.384 2.323 2.134 3.559 2.134.742 0 .833-.166.833-.453v-1.047c0-.333.07-.4.305-.4.174 0 .47.087 1.163.754.235.236.412.433.556.593.34.378.498.553.81.553h1.167c.334 0 .5-.166.404-.495-.105-.328-.483-.804-.984-1.367a7.45 7.45 0 0 0-.464-.483 3.582 3.582 0 0 1-.34-.358c-.173-.222-.123-.321 0-.519 0 0 1.422-2.002 1.57-2.682.075-.247 0-.429-.352-.429h-1.167a.504.504 0 0 0-.508.33s-.593 1.446-1.434 2.385c-.272.272-.396.359-.544.359-.074 0-.182-.087-.182-.334V13.43c0-.297-.086-.429-.333-.429h-1.834c-.185 0-.297.138-.297.268 0 .107.061.183.14.28.126.157.297.368.324.857v1.718c0 .377-.068.445-.216.445-.396 0-1.358-1.452-1.93-3.114-.11-.324-.223-.454-.521-.454Z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0ZM8 15.667c0-3.614 0-5.421 1.123-6.544C10.246 8 12.053 8 15.667 8h.666c3.614 0 5.422 0 6.544 1.123C24 10.246 24 12.053 24 15.667v.666c0 3.614 0 5.422-1.123 6.544C21.755 24 19.947 24 16.333 24h-.666c-3.614 0-5.421 0-6.544-1.123C8 21.755 8 19.947 8 16.333v-.666Z"
            fill="#fff"
        />
    </svg>
);

export default Vk;
