import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

import { formattedDate } from "../../lib";

const Card = styled(Box)(({ theme }) => ({
    display: "flex",
    background: "white",
    width: "100%",
    boxSizing: "border-box",
    border: "1px solid #CDCDCD",
    borderRadius: 8,
    padding: 16,
    transition: "all 500ms ease",
    cursor: "pointer",
    "&:hover": {
        boxShadow: "0px 0px 12px rgba(13, 153, 255, 0.5)",
        borderColor: "#CFEBFF",
    },
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
    },
}));

const Img = styled("img")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    height: 160,
    width: 200,
    borderRadius: 4,
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: 200,
    },
}));

const CommonCard = ({ image, name, short_desc, pub_date, id, route }) => {
    const navigate = useNavigate();

    const navigateDetail = () => {
        navigate(`${route}/${id}`);
    };

    return (
        <Card onClick={navigateDetail}>
            <Img alt="" src={image} />
            <Box
                sx={{
                    pl: {
                        xs: 0,
                        sm: 1,
                        md: 3,
                    },
                    mt: {
                        xs: 2,
                        sm: 0,
                    },
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            color: "customColors.grey",
                            fontSize: 16,
                        }}
                    >
                        {formattedDate(pub_date)}
                        <span
                            style={{
                                fontWeight: 600,
                                fontSize: 18,
                                marginLeft: 8,
                                color: "black",
                            }}
                        >
                            / {name}
                        </span>
                    </Typography>
                </Box>
                <Box sx={{ overflow: "hidden", mt: 1 }}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: 16,
                            WebkitLineClamp: 5,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            display: "-webkit-box",
                        }}
                    >
                        {short_desc}
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
};

export default CommonCard;
