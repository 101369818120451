import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import EmployersDetailBody from "./employersComponents/detail";
import { PageTitle, CommonLoading } from "../../components";
import { useGetEmployersDataIdQuery } from "../../services/EmployersService";

const EmployersDetailPage = () => {
    const params = useParams();
    const {
        data: employersDataId,
        isLoading: isEmployersDataIdLoading,
    } = useGetEmployersDataIdQuery({ id: params.id });

    if (isEmployersDataIdLoading) {
        return <CommonLoading />;
    }

    return (
        <Box>
            <PageTitle>{employersDataId.name}</PageTitle>
            <EmployersDetailBody employersDataId={employersDataId} />
        </Box>
    );
};

export default EmployersDetailPage;
