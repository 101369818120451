import { Box } from "@mui/material";

import { PartnersSlider } from "../../../../components";
import { HomeScreensLayout } from "../../../../layouts";
import { GhostPurpleSvg } from "../../../../static/img";
import ROUTES from "../../../../routes";

const PartnersScreen = ({ partnersData, generalLoading }) => {
    return (
        <HomeScreensLayout
            title="Партнеры службы занятости"
            buttonLabel="Все компании"
            path={ROUTES.PARTNERS_PAGE}
        >
            <Box sx={{ position: "relative" }}>
                <GhostPurpleSvg
                    style={{
                        position: "absolute",
                        left: -200,
                        top: 200,
                    }}
                />
            </Box>

            <PartnersSlider
                partnersData={partnersData}
                generalLoading={generalLoading}
            />
        </HomeScreensLayout>
    );
};

export default PartnersScreen;
