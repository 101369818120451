import React from "react";

const MessageParser = ({ children, actions }) => {
    const parse = (message) => {
        switch (message) {
            case "Помощь":
                actions.UnemployedInfo();
                break;
            case "Как встать на учет для признания безработным?":
                actions.AccountingUnemployed();
                break;
            case "В каких случаях могут отказать в признании безработным?":
                actions.ChoiseUnemployed();
                break;
            case "Какая помощь положена безработным?":
                actions.HelpUnemployed();
                break;
            case "Кто может получать пособие по безработице?":
                actions.WhoTakeUnemployed();
                break;
            case "Каков размер пособия по безработице?":
                actions.SizeUnemployed();
                break;
            case "Можно ли безработному выйти на пенсию досрочно?":
                actions.PensionUnemployed();
                break;
            case "Как пройти профессиональное обучение или повышение квалификации?":
                actions.DirectionUp();
                break;
            case "Как зарегистрировать статус самозанятого или открыть собственное дело?":
                actions.Selfemployed();
                break;
            case "Как воспользоваться программой временного трудоустройства?":
                actions.TemporaryUnemployed();
                break;
            case "Как принять участие в общественных работах?":
                actions.PublicUnemployed();
                break;
            default:
                actions.ErrorMessage();
        }
    };
    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions: {},
                });
            })}
        </div>
    );
};

export default MessageParser;
