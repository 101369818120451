import { api } from "./api";

export const antiCorruptionService = api.injectEndpoints({
    endpoints: (build) => ({
        getCorruptionList: build.query({
            query: () => `anticorruption/`,
        }),
        getCorruptionSection: build.query({
            query: () => `anticorruption/sections/`,
        }),
    }),
});

export const {
    useGetCorruptionListQuery,
    useGetCorruptionSectionQuery,
} = antiCorruptionService;
