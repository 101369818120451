import { Box } from "@mui/material";

import PartnersDetialImage from "./PartnersDetailImage";

const PartnersDetailBody = ({ partnersDataId }) => {
    return (
        <Box sx={{ p: 2, bgcolor: "white" }}>
            <PartnersDetialImage partnersDataId={partnersDataId} />
            <div
                dangerouslySetInnerHTML={{
                    __html: partnersDataId.description,
                }}
            ></div>
        </Box>
    );
};

export default PartnersDetailBody;
