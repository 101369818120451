import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const JobInfo = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 215,
    [theme.breakpoints.down("lg")]: {
        width: "100%",
        textAlign: "center",
    },
}));

const VacancyInfo = ({ num, label, ...props }) => {
    return (
        <JobInfo {...props}>
            <Typography
                color="secondary.main"
                sx={{
                    fontWeight: 600,
                    fontSize: {
                        md: 48,
                        sm: 48,
                        xs: 48,
                    },
                }}
            >
                {num}
            </Typography>
            <Box
                sx={{
                    border: "1px solid #E54D2B",
                    width: {
                        xs: 70,
                        md: 150,
                    },
                }}
            />
            <Typography
                color="customColors.lightDart"
                sx={{
                    mt: 1.5,
                    fontSize: {
                        md: 14,
                        xs: 14,
                    },
                    fontWeight: 500,
                    textAlign: "center",
                }}
            >
                {label}
            </Typography>
        </JobInfo>
    );
};

export default VacancyInfo;
