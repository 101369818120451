import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import { CitizensDetailBody } from "./citizensComponents";
import { PageTitle, CommonLoading } from "../../components";
import { useGetCitizensDataIdQuery } from "../../services/CitizensService";

const CitizensDetailPage = () => {
    const params = useParams();

    const {
        data: citizensDataId,
        isLoading: isCitizensDataIdLoading,
    } = useGetCitizensDataIdQuery({ id: params.id });

    if (isCitizensDataIdLoading) {
        return <CommonLoading />;
    }

    return (
        <Box>
            <PageTitle>{citizensDataId.name}</PageTitle>
            <CitizensDetailBody citizensDataId={citizensDataId} />
        </Box>
    );
};

export default CitizensDetailPage;
