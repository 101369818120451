import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: 0,
};
export const TestSlice = createSlice({
    name: "TestSlice",
    initialState,
    reducers: {
        getId(state, action) {
            state.id = action.payload;
        },
    },
});

export default TestSlice.reducer;
