import { useState } from "react";
import { Box } from "@mui/material";
import moment from "moment";

import { EventCalendarListBody } from "./eventCalendarComponents";
import {
    useGetEventCalendarDataQuery,
    useGetEventCalendarFullDataQuery,
} from "../../services/EventCalendarService";
import { CommonLoading } from "../../components";
import { usePagination } from "../../hooks";

const EventCalendarListPage = () => {
    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const { currentPage, handlePage, pageSize } = usePagination();

    const {
        data: eventCalendarData,
        isFetching: isEventCalendarDataFetching,
        isLoading: isEventCalendarDataLoading,
    } = useGetEventCalendarDataQuery({
        currentPage: currentPage,
        dateStart: moment(date[0].startDate).format("YYYY-MM-DD"),
        dateEnd: moment(date[0].endDate).format("YYYY-MM-DD"),
    });

    const {
        data: eventCalendarFullData,
        isLoading: isEventCalendarFullDataLoading,
    } = useGetEventCalendarFullDataQuery({
        currentPage: 1,
    });

    if (
        isEventCalendarDataLoading ||
        isEventCalendarFullDataLoading
    ) {
        return <CommonLoading />;
    }

    return (
        <Box>
            <EventCalendarListBody
                setDate={setDate}
                date={date}
                eventCalendarData={eventCalendarData}
                isEventCalendarDataFetching={
                    isEventCalendarDataFetching
                }
                eventCalendarFullData={eventCalendarFullData}
                pageSize={pageSize}
                handlePage={handlePage}
                currentPage={currentPage}
            />
        </Box>
    );
};

export default EventCalendarListPage;
