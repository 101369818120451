import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

import { SwitchPageTitle } from "./switchPageTitle";

const PageTitle = ({ children }) => {
    const location = useLocation();
    if (SwitchPageTitle(location.pathname) || children) {
        return (
            <Box sx={{ mb: 2 }}>
                <Typography
                    color="primary.main"
                    sx={{ fontWeight: 500, fontSize: 24 }}
                >
                    {children
                        ? children
                        : SwitchPageTitle(location.pathname)}
                </Typography>
                <Box
                    sx={{
                        border: "1px solid #828282",
                        width: "100%",
                        mt: 1,
                        opacity: 0.2,
                    }}
                ></Box>
            </Box>
        );
    }
};

export default PageTitle;
