import {
    CircularProgress,
    DialogContent,
    DialogTitle,
    Box,
    DialogActions,
    Button,
    IconButton,
    Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import { ModalsSlice } from "../../../reducer/ModalsSlice";
import { useGetTestsIdQuery } from "../../../services/TestService";
import TestModalForm from "./TestModalForm";
import { ModalLayout } from "../../../layouts";

const TestModalLoading = () => {
    return (
        <Box
            sx={{
                minHeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress />
        </Box>
    );
};

const TestModal = () => {
    const { handleTestModalOpen } = ModalsSlice.actions;
    const { testModalOpen } = useSelector((state) => state.ModalsSlice);
    const { id } = useSelector((state) => state.TestSlice);

    const { data: testIdData, isLoading: isTestIdDataLoading } =
        useGetTestsIdQuery({ id: id });

    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(handleTestModalOpen(false));
    };

    return (
        <ModalLayout open={testModalOpen} onClose={onClose}>
            <DialogTitle
                sx={{
                    mt: {
                        xs: 11,
                        sm: 0,
                    },
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid black",
                }}
            >
                <Typography
                    sx={{
                        fontSize: {
                            xs: 18,
                            sm: 23,
                        },
                        fontWeight: 600,
                    }}
                >
                    {testIdData?.name}
                </Typography>
                <IconButton onClick={onClose} sx={{ ml: 2 }}>
                    <CloseIcon fontSize="large" sx={{ color: "#1B4E9B" }} />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ mt: 2.4 }}>
                {isTestIdDataLoading ? (
                    <TestModalLoading />
                ) : (
                    <TestModalForm testIdData={testIdData} id={id} />
                )}
            </DialogContent>
            <DialogActions sx={{ borderTop: "1px solid black" }}>
                <Button variant="contained" type="submit" form="testForm">
                    Сохранить и отправить
                </Button>
            </DialogActions>
        </ModalLayout>
    );
};

export default TestModal;
