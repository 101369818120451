import { api } from "./api";

export const newsService = api.injectEndpoints({
    endpoints: (build) => ({
        getNewsList: build.query({
            query: ({ currentPage }) =>
                `news/?page=${currentPage}&ordering=-pub_date`,
        }),
        getNewsListId: build.query({
            query: ({ id }) => `news/${id}`,
        }),
    }),
});

export const { useGetNewsListQuery, useGetNewsListIdQuery } =
    newsService;
