import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    feedBackOpen: false,
    globalQuestionModalOpen: false,
    testModalOpen: false,
    resultTestModalOpen: false,
    result: "",
    score: 0,
};

export const ModalsSlice = createSlice({
    name: "ModalsSlice",
    initialState,
    reducers: {
        handleFeedBackModal(state, action) {
            state.feedBackOpen = action.payload;
        },
        handleGlobalQuestionModalOpen(state, action) {
            state.globalQuestionModalOpen = action.payload;
        },
        handleTestModalOpen(state, action) {
            state.testModalOpen = action.payload;
        },
        handleResultTestModalOpen(state, action) {
            state.resultTestModalOpen = action.payload;
        },
        handleResult(state, action) {
            state.result = action.payload;
        },
        handleScore(state, action) {
            state.score = action.payload;
        },
    },
});

export default ModalsSlice.reducer;
