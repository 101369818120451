import { Box, Typography } from "@mui/material";

const InteractiveMapInfo = ({ data }) => {
    const regionSZNInfo = [
        {
            label: "Наименование ЦЗН:",
            value: data?.name,
        },
        {
            label: "Горячая линия:",
            value: data?.hotline,
        },
        {
            label: "Сотовые телефоны:",
            value: data?.phones?.toString(),
        },
        {
            label: "Адрес:",
            value: data?.addressInfo,
        },
        {
            label: "Электронная почта:",
            value: data?.mail,
        },
    ];

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                mb: 2,
            }}
        >
            {regionSZNInfo.map((item, index) => (
                <Box
                    key={index}
                    sx={{
                        display: "flex",
                        flexDirection: "column",

                        gap: 0.5,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "customColors.dark",
                        }}
                    >
                        {item.label}
                    </Typography>
                    <Typography
                        sx={{
                            color: "customColors.grey",
                            fontWeight: 600,
                            fontSize: 14,
                        }}
                    >
                        {item.value}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default InteractiveMapInfo;
