import { Box, Typography } from "@mui/material";

const IntervalDateScreen = ({ data }) => {
    return (
        <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
            <Typography
                sx={{
                    color: "customColors.lightDart",
                    fontSize: 16,
                    fontWeight: 500,
                }}
            >
                {data.start_date}
            </Typography>{" "}
            -{" "}
            <Typography
                sx={{
                    color: "customColors.lightDart",
                    fontSize: 16,
                    fontWeight: 500,
                }}
            >
                {data.end_date}
            </Typography>
        </Box>
    );
};

export default IntervalDateScreen;
