import { Pagination } from "@mui/material";

const MuiPagination = ({
    totalPage,
    onChange,
    currentPage,
    pageSize,
}) => {
    const count = Math.ceil(totalPage / pageSize);
    return (
        <Pagination
            count={count}
            variant="outlined"
            shape="rounded"
            sx={{ mt: 5 }}
            page={currentPage}
            onChange={onChange}
        />
    );
};

export default MuiPagination;
