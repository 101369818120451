import { useGetFilialDataQuery } from "../../services/FilialService";
import { FilialTable, CommonLoading } from "../../components";

const FilialPage = () => {
    const { data: filialData, isLoading: isFilialDataLoading } =
        useGetFilialDataQuery({ currentPage: 1 });

    if (isFilialDataLoading) {
        return <CommonLoading />;
    }

    return (
        <div>
            <FilialTable filialData={filialData} />
        </div>
    );
};

export default FilialPage;
