import { Box, CircularProgress } from "@mui/material";

const CommonLoading = () => {
    return (
        <Box
            sx={{
                minHeight: 800,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default CommonLoading;
