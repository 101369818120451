import { useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import { TabMenu, Body } from "./testComponents";
import { useGetTestsListQuery } from "../../services/TestService";
import { CommonLoading, TestModal, ResultTestModal } from "../../components";

const TestPage = () => {
    const { data: testData, isFetching: isTestDataFetching } =
        useGetTestsListQuery({ currentPage: 1, pageSize: 20 });
    const { testModalOpen } = useSelector((state) => state.ModalsSlice);

    const [tabValue, setTabVlaue] = useState(1);

    const handleTabNavigation = (value) => {
        setTabVlaue(value);
    };

    if (isTestDataFetching) {
        return <CommonLoading />;
    }

    return (
        <Box sx={{ minHeight: 800 }}>
            <ResultTestModal />
            {testModalOpen && <TestModal />}
            <TabMenu
                tabValue={tabValue}
                handleTabNavigation={handleTabNavigation}
            />
            <Box sx={{ mt: 3 }}>
                <Body data={testData} />
            </Box>
        </Box>
    );
};

export default TestPage;