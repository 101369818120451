import { Skeleton, Box } from "@mui/material";

const VacancyCountSkeletonLoading = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: {
                    xs: "center",
                    md: "start",
                },
                width: "100%",
            }}
        >
            <Skeleton
                variant="rounded"
                sx={{
                    width: 215,
                    height: 123,
                    ml: {
                        md: 5,
                        xs: 0,
                    },
                }}
            />
        </Box>
    );
};

export default VacancyCountSkeletonLoading;
