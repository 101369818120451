import { Box } from "@mui/material";

import { CommonLoading, MuiPagination } from "../../components";
import { useGetEmployersDataQuery } from "../../services/EmployersService";
import EmployersListBody from "./employersComponents/list";
import { usePagination } from "../../hooks";

const EmployersListPage = () => {
    const { currentPage, handlePage, pageSize } = usePagination();

    const { data: employersData, isLoading: isEmployersDataLoading } =
        useGetEmployersDataQuery({ currentPage: currentPage });

    if (isEmployersDataLoading) {
        return <CommonLoading />;
    }

    return (
        <Box sx={{ mb: 2 }}>
            <EmployersListBody employersData={employersData} />
            <MuiPagination
                pageSize={pageSize}
                totalPage={employersData.count}
                onChange={handlePage}
                currentPage={currentPage}
            />
        </Box>
    );
};

export default EmployersListPage;
