import { api } from "./api";

export const faqService = api.injectEndpoints({
    endpoints: (build) => ({
        getFaqData: build.query({
            query: () => `qna/?page=${1}`,
        }),
    }),
});

export const { useGetFaqDataQuery } = faqService;
