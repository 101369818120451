import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { usePostFeedBackMutation } from "../../../services/FeedBackService";
import { ModalsSlice } from "../../../reducer/ModalsSlice";

const schema = yup
    .object()
    .shape({
        full_name: yup
            .string()
            .matches(/^([^0-9]*)$/, "Введите корректные данные")
            .required("Обязательное поле"),
        phone_number: yup
            .number()
            .typeError("Обязательное поле")
            .test(
                "len",
                "Введите корректные данные",
                (val) => val.toString().length === 11
            )
            .required("Обязательное поле"),
        message: yup.string().required("Обязательное поле"),
    })
    .required();

const FeedBackTools = () => {
    const [postFeedBack] = usePostFeedBackMutation();

    const { handleFeedBackModal } = ModalsSlice.actions;

    const dispatch = useDispatch();

    const onFinish = (data) => {
        postFeedBack({ user_type: "USER", ...data }).then((res) => {
            if (res.data) {
                toast.success("Ваше сообщение отправлено");
                dispatch(handleFeedBackModal(false));
            } else {
                toast.error("Ваше сообщение не отправлено");
            }
        });
    };

    return { onFinish, schema };
};

export default FeedBackTools;
