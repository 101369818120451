import { Grid } from "@mui/material";

import { useGetCorruptionSectionQuery } from "../../services/AntiCorruptionService";
import CorruptionAccardion from "./components/CorruptionAccardion";
import { CommonLoading } from "../../components";

const AntiCorruptionPage = () => {
    const {
        data: corruptionList,
        isLoading: isCorruptionListLoading,
    } = useGetCorruptionSectionQuery();

    if (isCorruptionListLoading) {
        return <CommonLoading />;
    }

    return (
        <Grid container spacing={2}>
            {corruptionList.results?.map((item, index) => (
                <Grid
                    item
                    key={index}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                >
                    <CorruptionAccardion
                        name={item.name}
                        description={item.description}
                        documents={item.documents}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default AntiCorruptionPage;
