import { Typography, CardActionArea } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

import ROUTES from "../../routes";

const PreviewPartnerCard = ({ img, description, type, id }) => {
    const Card = styled(CardActionArea)(({ theme }) => ({
        width: type === "page" ? "100%" : 216,
        borderRadius: 12,
        marginTop: 20,
        marginBottom: 20,
        background: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: type === "page" ? 0 : 15,
        border: "1px solid #CDCDCD",
        cursor: "pointer",
        height: 200,
    }));

    const navigate = useNavigate();

    const navigation = () => {
        navigate(ROUTES.PARTNERS_DETAIL_PAGE, {
            state: {
                id: id,
            },
        });
    };
    return (
        <Card onClick={navigation}>
            <img
                alt=""
                src={img}
                style={{
                    objectFit: "contain",
                    margin: "0 auto",
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                    height: 100,
                    width: "100%",
                }}
            />
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: 14,
                    mt: 1.2,
                    mb: 3,
                    width: "80%",
                    textAlign: "center",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    display: "-webkit-box",
                }}
            >
                {description}
            </Typography>
        </Card>
    );
};

export default PreviewPartnerCard;
