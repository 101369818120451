import { useEffect, useState } from "react";

const useMobileVersion = ({ windowWidth }) => {
    const [mobileVersion, setMobileVersion] = useState();

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < windowWidth) {
                setMobileVersion(true);
            }
            if (window.innerWidth > windowWidth) {
                setMobileVersion(false);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
    }, []);

    return {
        mobileVersion,
    };
};

export default useMobileVersion;
