import {
    Box,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import { ModalLayout } from "../../layouts";
import { ModalsSlice } from "../../reducer/ModalsSlice";

const ResultTestModal = () => {
    const { handleResultTestModalOpen } = ModalsSlice.actions;
    const { resultTestModalOpen, result, score } = useSelector(
        (state) => state.ModalsSlice
    );

    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(handleResultTestModalOpen(false));
    };

    return (
        <ModalLayout open={resultTestModalOpen} onClose={onClose}>
            <DialogTitle
                sx={{
                    mt: {
                        xs: 11,
                        sm: 0,
                    },
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid black",
                }}
            >
                <Typography
                    sx={{
                        fontSize: {
                            xs: 18,
                            sm: 23,
                        },
                        fontWeight: 600,
                    }}
                >
                    Результаты теста
                </Typography>
                <IconButton onClick={onClose} sx={{ ml: 2 }}>
                    <CloseIcon
                        fontSize="large"
                        sx={{ color: "#1B4E9B" }}
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        pt: 2,
                    }}
                >
                    <Typography>Результат: {result}</Typography>
                    <Typography>Количество очков: {score}</Typography>
                </Box>
            </DialogContent>
        </ModalLayout>
    );
};

export default ResultTestModal;
