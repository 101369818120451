import { useState } from "react";

const usePagination = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const handlePage = (event, value) => {
        setCurrentPage(value);
    };

    const handlePageSize = (value) => {
        setPageSize(value);
    };

    return {
        currentPage,
        handlePage,
        pageSize,
        handlePageSize,
    };
};

export default usePagination;
