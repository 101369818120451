import { Box, Typography } from "@mui/material";

import list from "./list";

const TesrmOfUse = () => {
    return (
        <Box>
            {list.map((item, index) => (
                <Box key={index}>
                    <Typography
                        sx={{ fontWeight: 600, fontSize: 20, mt: 3 }}
                    >
                        {item.title}
                    </Typography>
                    <Box>
                        {item.texts.map((textItem, index) => (
                            <Typography
                                key={index}
                                sx={{
                                    mt: 2,
                                    wordSpacing: 5,
                                    fontSize: 15,
                                }}
                            >
                                {textItem}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default TesrmOfUse;
