import React from "react";
import { Dialog, Slide } from "@mui/material";

import { useMobileVersion } from "../../hooks";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalLayout = ({ open, onClose, children, maxWidth }) => {
    const { mobileVersion } = useMobileVersion({ windowWidth: 600 });

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            onClose={onClose}
            fullScreen={mobileVersion}
            fullWidth={!mobileVersion}
            maxWidth={maxWidth ? maxWidth : "md"}
            sx={{
                zIndex: {
                    sm: 100000,
                    xs: 10,
                },
            }}
        >
            {children}
        </Dialog>
    );
};

export default ModalLayout;
