import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import { PageTitle, CommonLoading } from "../../components";
import { useGetNewsListIdQuery } from "../../services/NewsService";
import { NewsDetailBody } from "./newsComponents";

const NewsDetailPage = () => {
    const params = useParams();

    const { data: newsDataId, isLoading: isNewsDataIdLoading } =
        useGetNewsListIdQuery({ id: params.id });

    if (isNewsDataIdLoading) {
        return <CommonLoading />;
    }

    return (
        <Box>
            <PageTitle>{newsDataId.name}</PageTitle>
            <NewsDetailBody newsDataId={newsDataId} />
        </Box>
    );
};

export default NewsDetailPage;
