import { useGetNewsListQuery } from "../../services/NewsService";
import { useGetPartnersDataQuery } from "../../services/PartnersService";
import { useGetPromoBlocksListQuery } from "../../services/PromoBlocksService";
import { useGetTestsListQuery } from "../../services/TestService";
import { useGetVacancyCountQuery } from "../../services/VacancyService";

const HomeTools = () => {
    const { data: newsData, isLoading: isNewsListLoading } =
        useGetNewsListQuery({ currentPage: 1 });
    const { data: partnersData, isLoading: isPartnersDataLoading } =
        useGetPartnersDataQuery({ currentPage: 1 });
    const { data: vacancyCount, isLoading: isVacancyCountLoading } =
        useGetVacancyCountQuery();
    const {
        data: promoBlocksList,
        isLoading: isPromoBlocksListLoading,
    } = useGetPromoBlocksListQuery();
    const { data: testList, isLoading: isTestListLoading } =
        useGetTestsListQuery({ pageSize: 4 });

    const generalLoading = () => {
        if (
            isNewsListLoading ||
            isPartnersDataLoading ||
            isPromoBlocksListLoading ||
            isTestListLoading ||
            isVacancyCountLoading
        ) {
            return true;
        } else {
            return false;
        }
    };

    return {
        newsData,
        partnersData,
        generalLoading,
        promoBlocksList,
        testList,
        vacancyCount,
    };
};

export default HomeTools;
