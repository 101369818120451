import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { YandexMap } from "../..";
import InteractiveMapInfo from "./InteractiveMapInfo";
import { InteractiveMapSlice } from "../../../reducer/InteractiveMapSlice";

const InteractionMapModal = () => {
    const { showInteractiveMapModal, regionData } = useSelector(
        (state) => state.InteractiveMapSlice
    );
    const { handleOpenInteractiveMapModal } =
        InteractiveMapSlice.actions;

    const dispatch = useDispatch();

    const closeInteractiveMapModal = () => {
        dispatch(handleOpenInteractiveMapModal(false));
    };

    return (
        <Dialog
            open={showInteractiveMapModal}
            onClose={closeInteractiveMapModal}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Typography
                    color="secondary.main"
                    sx={{ fontWeight: 600, fontSize: 24 }}
                >
                    {regionData.ariaLabel}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <InteractiveMapInfo data={regionData} />
                <YandexMap coordinates={regionData.coordinates} />
            </DialogContent>
        </Dialog>
    );
};

export default InteractionMapModal;
