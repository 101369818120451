import { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Accardion = ({ title, description }) => {
    const [accordionOpen, setAccordionOpen] = useState(false);

    const handleAccordionOpen = () => {
        setAccordionOpen(!accordionOpen);
    };

    return (
        <Accordion
            sx={{
                boxShadow: "none",
                border: accordionOpen
                    ? "2px solid #6AB9E8"
                    : "1px solid #D2D2D2",
                background:
                    accordionOpen &&
                    "linear-gradient(90deg, #E6F5FF 0%, #FFFBFA 100%)",
            }}
            onChange={handleAccordionOpen}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ ml: 1 }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    minHeight: 72,
                }}
            >
                <Typography
                    sx={{
                        color: "primary.main",
                        fontSize: 16,
                        fontWeight: 500,
                        textTransform: "uppercase",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        display: "-webkit-box",
                    }}
                >
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                    sx={{
                        color: "primary.main",
                        fontSize: 14,
                        fontWeight: 500,
                        wordWrap: "break-word",
                    }}
                >
                    {description}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};

export default Accardion;
