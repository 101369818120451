import { api } from "./api";

export const feedBackService = api.injectEndpoints({
    endpoints: (build) => ({
        postFeedBack: build.mutation({
            query(body) {
                return {
                    url: `feedback/user/`,
                    method: "POST",
                    body,
                };
            },
        }),
    }),
});

export const { usePostFeedBackMutation } = feedBackService;
