import { Skeleton } from "@mui/material";

const NewsSliderCardSkeletonLaoding = () => {
    return (
        <Skeleton
            variant="rounded"
            sx={{ width: 222, height: 309, marginLeft: 1 }}
        />
    );
};

export default NewsSliderCardSkeletonLaoding;
