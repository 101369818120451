import { Skeleton } from "@mui/material";

const PartnersSliderCardSkeletonLoading = () => {
    return (
        <Skeleton
            variant="rounded"
            sx={{ width: 216, height: 200, mr: 1.5 }}
        />
    );
};

export default PartnersSliderCardSkeletonLoading;
