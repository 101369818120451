import { Box, Typography } from "@mui/material";

const InformationScreen = ({ data }) => {
    const navigationAnotherSite = () => {
        window.location.href = data.url;
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mt: 2,
            }}
        >
            <Typography
                sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#828282",
                }}
            >
                Источник
                <span
                    onClick={navigationAnotherSite}
                    style={{ marginLeft: 5, cursor: "pointer" }}
                >
                    {data.url}
                </span>
            </Typography>
            <Box sx={{ display: "flex" }}>
                <Typography
                    sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#828282",
                    }}
                >
                    {data.pub_date}
                </Typography>
            </Box>
        </Box>
    );
};

export default InformationScreen;
