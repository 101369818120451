import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { api } from "../services/api";

import ModalsSlice from "../reducer/ModalsSlice";
import HomeDataSlice from "../reducer/HomeDataSlice";
import ChatBotSlice from "../reducer/ChatBotSlice";
import MobileDrawerSlice from "../reducer/MobileDrawerSlice";
import InteractiveMapSlice from "../reducer/InteractiveMapSlice";
import TestSlice from "../reducer/TestSlice";

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    ModalsSlice,
    HomeDataSlice,
    ChatBotSlice,
    MobileDrawerSlice,
    InteractiveMapSlice,
    TestSlice,
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(api.middleware),
    });
};
