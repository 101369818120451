import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import { EventCalendarDetailBody } from "./eventCalendarComponents";
import { useGetEventCalendarDataIdQuery } from "../../services/EventCalendarService";
import { CommonLoading } from "../../components";

const EventCalendarDetailPage = () => {
    const params = useParams();

    const {
        data: eventCalendarDataId,
        isLoading: isEventCalendarDataId,
    } = useGetEventCalendarDataIdQuery({ id: params.id });

    if (isEventCalendarDataId) {
        return <CommonLoading />;
    }

    return (
        <Box>
            <EventCalendarDetailBody
                eventCalendarDataId={eventCalendarDataId}
            />
        </Box>
    );
};

export default EventCalendarDetailPage;
