import { useLocation } from "react-router-dom";

import { PageTitle, CommonLoading } from "../../components";
import { useGetPartnersDataIdQuery } from "../../services/PartnersService";
import { PartnersDetailBody } from "./partnersComponents";

const PartnersDetailPage = () => {
    const location = useLocation();

    const state = location.state;

    const { id } = state;

    const {
        data: partnersDataId,
        isLoading: isPartnerDataIdLoading,
    } = useGetPartnersDataIdQuery({ id: id });

    if (isPartnerDataIdLoading) {
        return <CommonLoading />;
    }

    return (
        <div>
            <PageTitle>{partnersDataId.name}</PageTitle>
            <PartnersDetailBody partnersDataId={partnersDataId} />
        </div>
    );
};

export default PartnersDetailPage;
