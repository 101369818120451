import { MuiPagination, CommonLoading } from "../../components";
import { useGetContestsDataQuery } from "../../services/ContestsService";
import { ContestsListBody } from "./contestsComponents";
import { usePagination } from "../../hooks";

const ContestsListPage = () => {
    const { currentPage, handlePage, pageSize } = usePagination();

    const { data: contestData, isLoading: isContestsDataLoading } =
        useGetContestsDataQuery({ currentPage: currentPage });

    if (isContestsDataLoading) {
        return <CommonLoading />;
    }

    return (
        <div>
            <ContestsListBody contestData={contestData} />
            <MuiPagination
                pageSize={pageSize}
                totalPage={contestData.count}
                onChange={handlePage}
                currentPage={currentPage}
            />
        </div>
    );
};

export default ContestsListPage;
