import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-chatbot-kit/build/main.css";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./App.css";

import ROUTES from "./routes";
import {
    Home,
    Vacancy,
    NewsPage,
    TestPage,
    NewsDetailPage,
    EventCalendarPage,
    CitizensServicePage,
    ContestsPage,
    EmployersPage,
    InteractiveMapPage,
    PartnersPage,
    FaqPage,
    FilialPage,
    DocumentsPage,
    PartnersDetailPage,
    CitizensDetailPage,
    EmployersDetailPage,
    ContestsDetailPage,
    TesrmOfUse,
    EventCalendarDetailPage,
    AntiCorruptionPage,
    MinorsEmploymentPage,
} from "./pages";
import theme from "./MuiTheme";
import { MainLayout } from "./layouts";
import {
    FeedBackModal,
    FixedButton,
    GlobalQuestionModal,
    ChatBot,
    MobileDrawer,
} from "./components";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <ToastContainer autoClose={1000} />
                <ChatBot />
                <FeedBackModal />
                <FixedButton />
                <GlobalQuestionModal />
                <MobileDrawer />
                <Routes>
                    <Route
                        path={ROUTES.HOME}
                        element={<MainLayout />}
                    >
                        <Route index element={<Home />} />
                        <Route
                            path={ROUTES.BANK_VACANCY}
                            element={<Vacancy />}
                        />
                        <Route
                            path={ROUTES.ANTI_CORRUPTION_PAGE}
                            element={<AntiCorruptionPage />}
                        />
                        <Route
                            path={ROUTES.MINORS_EMPLOYMENT_PAGE}
                            element={<MinorsEmploymentPage />}
                        />
                        <Route
                            path={ROUTES.TERM_OF_USE_PAGE}
                            element={<TesrmOfUse />}
                        />
                        <Route
                            path={`${ROUTES.CONTESTS_DETAIL_PAGE}/:id`}
                            element={<ContestsDetailPage />}
                        />
                        <Route
                            path={ROUTES.PARTNERS_PAGE}
                            element={<PartnersPage />}
                        />
                        <Route
                            path={ROUTES.PARTNERS_DETAIL_PAGE}
                            element={<PartnersDetailPage />}
                        />
                        <Route
                            path={ROUTES.NEWS_PAGE}
                            element={<NewsPage />}
                        />
                        <Route
                            path={ROUTES.TEST_PAGE}
                            element={<TestPage />}
                        />
                        <Route
                            path={`${ROUTES.NEWS_DETAIL_PAGE}/:id`}
                            element={<NewsDetailPage />}
                        />
                        <Route
                            path={ROUTES.EVENT_CALENDAR_PAGE}
                            element={<EventCalendarPage />}
                        />
                        <Route
                            path={ROUTES.CITIZENS_SERVICE_PAGE}
                            element={<CitizensServicePage />}
                        />
                        <Route
                            path={ROUTES.EMPLOYERS_PAGE}
                            element={<EmployersPage />}
                        />
                        <Route
                            path={ROUTES.CONTESTS_PAGE}
                            element={<ContestsPage />}
                        />
                        <Route
                            path={ROUTES.INTERACTIVE_MAP_PAGE}
                            element={<InteractiveMapPage />}
                        />
                        <Route
                            path={ROUTES.FAQ_PAGE}
                            element={<FaqPage />}
                        />
                        <Route
                            path={ROUTES.FILIAL_PAGE}
                            element={<FilialPage />}
                        />
                        <Route
                            path={ROUTES.DOCUMENTS_PAGE}
                            element={<DocumentsPage />}
                        />
                        <Route
                            path={`${ROUTES.CITIZENS_DETAIL_PAGE}/:id`}
                            element={<CitizensDetailPage />}
                        />
                        <Route
                            path={`${ROUTES.EMPLOYERS_PAGE_DETAIL}/:id`}
                            element={<EmployersDetailPage />}
                        />
                        <Route
                            path={`${ROUTES.EVENT_CALENDAR_DETAIL_PAGE}/:id`}
                            element={<EventCalendarDetailPage />}
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
