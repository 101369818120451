import { Drawer, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { MobileDrawerSlice } from "../../../reducer/MobileDrawerSlice";
import MobileDrawerSideMenu from "./MobileDrawerSideMenu";
import MobileDraweBody from "./MobileDrawerBody";
import menuList from "../../headers/menuList";

const MobileDrawer = () => {
    const { handleShowDrawer } = MobileDrawerSlice.actions;
    const { showMobileDrawer } = useSelector(
        (state) => state.MobileDrawerSlice
    );

    const dispatch = useDispatch();

    const closeMobileDrawer = () => {
        dispatch(handleShowDrawer(false));
    };

    return (
        <Drawer
            open={showMobileDrawer}
            anchor="right"
            onClose={closeMobileDrawer}
        >
            <Box sx={{ width: 300, pt: 10 }}>
                <MobileDraweBody menuList={menuList} />
                <MobileDrawerSideMenu />
            </Box>
        </Drawer>
    );
};

export default MobileDrawer;
