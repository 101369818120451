import { lazy, Suspense } from "react";

import {
    HomeScreensLayout,
    InteractiveMapLayout,
} from "../../../../layouts";

const LazyInteractiveMap = lazy(() =>
    import("../../../../components/maps/interactiveMap")
);

const MapScreen = () => {
    return (
        <HomeScreensLayout title="Интерактивная карта центров занятости">
            <InteractiveMapLayout>
                <Suspense fallback={<div>Loading...</div>}>
                    <LazyInteractiveMap />
                </Suspense>
            </InteractiveMapLayout>
        </HomeScreensLayout>
    );
};

export default MapScreen;
