import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CustomMenuItem } from "../..";
import { MobileDrawerSlice } from "../../../reducer/MobileDrawerSlice";

const MobileDraweBody = ({ menuList }) => {
    const { showMobileDrawerSideMenu } = useSelector(
        (state) => state.MobileDrawerSlice
    );
    const {
        handleShowMobileDrawerSideMenu,
        getSideMenuList,
        handleShowDrawer,
    } = MobileDrawerSlice.actions;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const openSideMenu = (item) => {
        dispatch(handleShowMobileDrawerSideMenu(true));
        dispatch(getSideMenuList(item));
    };

    const navigation = (path) => {
        dispatch(handleShowDrawer(false));
        navigate(path);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                padding: 2,
                opacity: showMobileDrawerSideMenu ? 0 : 1,

                zIndex: showMobileDrawerSideMenu ? 0 : 10,
                position: showMobileDrawerSideMenu
                    ? "absolute"
                    : "relative",
                transition: "all 500ms ease",
            }}
        >
            {menuList.map((item, index) => (
                <Box key={index}>
                    {item.type === "menuItem" && (
                        <CustomMenuItem
                            onClick={() => navigation(item.path)}
                            color="#1B4E9B"
                            fontSize={18}
                            fontWeight={500}
                        >
                            {item.label}
                        </CustomMenuItem>
                    )}
                    {item.type === "dropDown" && (
                        <CustomMenuItem
                            color="#1B4E9B"
                            fontSize={18}
                            fontWeight={500}
                            onClick={() =>
                                openSideMenu(item.dropDownList)
                            }
                        >
                            {item.label}
                        </CustomMenuItem>
                    )}
                </Box>
            ))}
        </Box>
    );
};

export default MobileDraweBody;
