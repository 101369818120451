import {
    createApi,
    fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
    // baseUrl: "https://backend.xn--80aacf4aam3apxdl8m.xn--p1ai/api/v1/",
    prepareHeaders: (headers) => {
        return headers;
    },
});

export const api = createApi({
    reducerPath: "splitApi",

    baseQuery: baseQuery,

    tagTypes: [],

    endpoints: () => ({}),
});
