import React from "react";
import {
    IconButton,
    DialogTitle,
    Typography,
    DialogContent,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import { ModalsSlice } from "../../../reducer/ModalsSlice";
import FeedBackForm from "./FeedBackForm";
import { ModalLayout } from "../../../layouts";

const FeedBackModal = () => {
    const { feedBackOpen } = useSelector(
        (state) => state.ModalsSlice
    );

    const dispatch = useDispatch();
    const { handleFeedBackModal } = ModalsSlice.actions;

    const handleClose = () => {
        dispatch(handleFeedBackModal(false));
    };

    return (
        <ModalLayout open={feedBackOpen} onClose={handleClose}>
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: {
                        xs: 15,
                        sm: 0,
                    },
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: 22,
                        textAlign: "center",
                    }}
                >
                    Обратная связь
                </Typography>
                <IconButton
                    sx={{ right: 20, position: "absolute" }}
                    onClick={handleClose}
                >
                    <CloseIcon
                        fontSize="large"
                        sx={{ color: "#1B4E9B" }}
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FeedBackForm />
            </DialogContent>
        </ModalLayout>
    );
};

export default FeedBackModal;
