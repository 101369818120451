import { Box } from "@mui/material";
import Slider from "react-slick";
import { styled } from "@mui/material";

import { PromoSliderImgSkeletonLoading } from "..";

const PromoImg = styled("img")(({ theme }) => ({
    width: "97%",
    borderRadius: 6,
    boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.15)",
    margin: "0 auto",
    border: "none",
    objectFit: "cover",
    height: 320,
    [theme.breakpoints.down("md")]: {
        height: 400,
    },
    [theme.breakpoints.down("sm")]: {
        height: 300,
    },
    [theme.breakpoints.down(473)]: {
        height: 200,
    },
    background: "white",
    cursor: "pointer",
    "&:hover": {
        boxShadow: "0px 0px 12px rgba(13, 153, 255, 0.5)",
    },
    transition: "all 700ms ease",
}));

const PromoBlockSlider = ({ promoBlocksList, generalLoading }) => {
    const settings = {
        dots: true,
        centerPadding: "0px",
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplaySpeed: 4000,
        autoplay: true,
    };

    return (
        <Slider {...settings}>
            {generalLoading() ? (
                <PromoSliderImgSkeletonLoading />
            ) : (
                promoBlocksList?.results.map((item, index) => (
                    <Box
                        sx={{ border: "none", pb: 2, pt: 2 }}
                        key={index}
                    >
                        <PromoImg
                            border="0"
                            alt=""
                            src={item.banner}
                            onClick={() =>
                                (window.location.href = item.link)
                            }
                        />
                    </Box>
                ))
            )}
        </Slider>
    );
};

export default PromoBlockSlider;
