import { Box } from "@mui/material";

import DocumentsBody from "./documentsComponents/DocumentsBody";
import { useGetDocumentsDataQuery } from "../../services/DocumentsService";
import { CommonLoading } from "../../components";

const DocumentsPage = () => {
    const { data: documentsData, isLoading: isDocumentsDataLoading } =
        useGetDocumentsDataQuery();

    if (isDocumentsDataLoading) {
        return <CommonLoading />;
    }

    return (
        <Box>
            <DocumentsBody documentsList={documentsData.results} />
        </Box>
    );
};

export default DocumentsPage;
