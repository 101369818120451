import { styled } from "@mui/system";

const Img = styled("img")(({ theme }) => ({
    width: 300,
    height: 300,
    objectFit: "contain",
    [theme.breakpoints.down(691)]: {
        width: "100%",
    },
    [theme.breakpoints.down(505)]: {
        height: 300,
    },
}));

const PartnersDetailImage = ({ partnersDataId }) => {
    return <Img src={partnersDataId.banner} alt="" />;
};

export default PartnersDetailImage;
