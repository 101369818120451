import { api } from "./api";

export const documentsService = api.injectEndpoints({
    endpoints: (build) => ({
        getDocumentsData: build.query({
            query: () => `publicdocks/list/?page=1`,
        }),
    }),
});

export const { useGetDocumentsDataQuery } = documentsService;
