import { InteractiveMapLayout } from "../../layouts";
import {
    InteractiveMap,
    InteractionMapModal,
} from "../../components";
import StaticInformation from "./interactiveMapComponents/StaticInformation";

const InteractiveMapPage = () => {
    return (
        <div>
            <StaticInformation />
            <InteractionMapModal />
            <InteractiveMapLayout>
                <InteractiveMap />
            </InteractiveMapLayout>
        </div>
    );
};

export default InteractiveMapPage;
