import {
    Box,
    Container,
    Typography,
    IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { Sling as Hamburger } from "hamburger-react";
import { useNavigate } from "react-router-dom";

import { MobileDrawerSlice } from "../../reducer/MobileDrawerSlice";
import ROUTES from "../../routes";

const TextWrapper = styled(Box)(({ theme }) => ({}));

const MobileHeader = () => {
    const { handleShowDrawer } = MobileDrawerSlice.actions;
    const { showMobileDrawer } = useSelector(
        (state) => state.MobileDrawerSlice
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const openMobileDrawer = () => {
        dispatch(handleShowDrawer(!showMobileDrawer));
    };

    const navigateHome = () => {
        navigate(ROUTES.HOME);
    };

    return (
        <Box
            sx={{
                bgcolor: "white",
                paddingTop: 1,
                paddingBottom: 1,
                width: "100%",
                display: {
                    xs: "block",
                    md: "none",
                },
                position: "fixed",
                zIndex: 10000,
                boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
            }}
        >
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={navigateHome}
                >
                    <img src="/img/image 65appBap_icon.svg" alt="" />
                    <TextWrapper>
                        <Typography
                            color="primary.main"
                            sx={{
                                fontWeight: 500,
                                fontSize: 18,
                                ml: 1,
                            }}
                        >
                            Работа в Якутии
                        </Typography>
                    </TextWrapper>
                </Box>

                <Box>
                    <IconButton onClick={openMobileDrawer}>
                        <Hamburger
                            toggled={showMobileDrawer}
                            color="#1B4E9B"
                        />
                    </IconButton>
                </Box>
            </Container>
        </Box>
    );
};

export default MobileHeader;
