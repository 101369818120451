import ROUTES from "../../routes";

const menuList = [
    {
        label: "Главная",
        path: ROUTES.HOME,
        type: "menuItem",
    },
    {
        label: "Гражданам",
        type: "dropDown",
        dropDownList: [
            {
                label: "Поиск работы",
                path: ROUTES.BANK_VACANCY,
            },
            {
                label: "Услуги гражданам",
                path: ROUTES.CITIZENS_SERVICE_PAGE,
            },
            {
                label: "Тесты и опросы",
                path: ROUTES.TEST_PAGE,
            },
            {
                label: "Вопрос / ответ",
                path: ROUTES.FAQ_PAGE,
            },
        ],
    },
    {
        label: "Работодателям",
        dropDownList: [
            {
                label: "Услуги работодателям",
                path: ROUTES.EMPLOYERS_PAGE,
            },
            {
                label: "Конкурсы / отборы",
                path: ROUTES.CONTESTS_PAGE,
            },
        ],
        type: "dropDown",
    },
    {
        label: "Информация",
        dropDownList: [
            {
                label: "Филиалы и представительства ЦЗН РС(Я)",
                path: ROUTES.FILIAL_PAGE,
            },
            {
                label: "Новостная лента",
                path: ROUTES.NEWS_PAGE,
            },
            {
                label: "Интерактивная карта ЦЗН",
                path: ROUTES.INTERACTIVE_MAP_PAGE,
            },
            {
                label: "Общая информация",
                path: ROUTES.DOCUMENTS_PAGE,
            },
            {
                label: "Партнеры",
                path: ROUTES.PARTNERS_PAGE,
            },
            {
                label: "Календарь мероприятий",
                path: ROUTES.EVENT_CALENDAR_PAGE,
            },
            {
                label: "Пользовательское соглашение",
                path: ROUTES.TERM_OF_USE_PAGE,
            },
            {
                label: "Документы ГКУ РС(Я) ЦЗН РС(Я)",
                path: ROUTES.ANTI_CORRUPTION_PAGE,
            },
            {
                label: "Трудоустройство несовершеннолетних",
                path: ROUTES.MINORS_EMPLOYMENT_PAGE,
            },
        ],
        type: "dropDown",
    },
];

export default menuList;
