import { Grid, Box } from "@mui/material";

import Calendar from "./Calendar";
import EventList from "./EventList";
import LastEvent from "./LastEvent";
import { MuiPagination } from "../../../../components";

const EventCalendarListBody = ({
    eventCalendarData,
    setDate,
    date,
    isEventCalendarDataFetching,
    eventCalendarFullData,
    pageSize,
    handlePage,
    currentPage,
}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                <Calendar date={date} setDate={setDate} />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={8}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                }}
            >
                <LastEvent data={eventCalendarFullData} />
                <Box>
                    <EventList
                        data={eventCalendarData}
                        isEventCalendarDataFetching={
                            isEventCalendarDataFetching
                        }
                        date={date}
                    />
                    <MuiPagination
                        pageSize={pageSize}
                        totalPage={eventCalendarData?.count}
                        onChange={handlePage}
                        currentPage={currentPage}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default EventCalendarListBody;
