import { Box, Container, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
    YouTubeIcon,
    OkIcon,
    VkIcon,
    AppStoreIcon,
    GooglePlayIcon,
    TelegramIcon,
    RutubeIcon,
} from "../../static/icons";
import footerList from "./FooterList";

const Footer = () => {
    const navigate = useNavigate();

    const shareSocialNetworks = (link) => {
        window.location.href = link;
    };

    return (
        <Box
            sx={{
                bgcolor: "customColors.darkdark",
                paddingTop: 4.2,
                paddingBottom: 5,
            }}
        >
            <Container>
                <Grid container spacing={2}>
                    {footerList.map((item, index) => (
                        <Grid item key={index} xl={3}>
                            <Typography color="customColors.white">{item.title}</Typography>
                            {item.values.map((item, index) => (
                                <Typography
                                    key={index}
                                    sx={{
                                        mt: 1,
                                        fontSize: 14,
                                        color: "customColors.white",
                                        opacity: 0.4,
                                        transition: "all 500ms ease",
                                        cursor: "pointer",
                                        width: "max-content",
                                        "&:hover": {
                                            opacity: 1,
                                        },
                                    }}
                                    onClick={() => navigate(item.path)}
                                >
                                    {item.label}
                                </Typography>
                            ))}
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <YouTubeIcon
                        style={{
                            marginTop: 15,
                            marginRight: 15,
                            cursor: "pointer",
                        }}
                        onClick={() => shareSocialNetworks("https://www.youtube.com/channel/UCMeJrUyejc3q4LPjMkaFg6g")}
                    />
                    <img
                        src={TelegramIcon}
                        style={{
                            marginTop: 15,
                            marginRight: 15,
                            cursor: "pointer",
                            width: 33,
                        }}
                        alt=""
                        onClick={() => shareSocialNetworks("https://t.me/czn_yakutia")}
                    />
                    <img
                        src={RutubeIcon}
                        style={{
                            marginTop: 15,
                            marginRight: 15,
                            cursor: "pointer",
                            width: 34,
                        }}
                        alt=""
                        onClick={() => shareSocialNetworks("https://rutube.ru/channel/25854477/")}
                    />

                    <VkIcon
                        style={{
                            marginTop: 15,
                            marginRight: 15,
                            cursor: "pointer",
                        }}
                        onClick={() => shareSocialNetworks("https://vk.com/club211411405")}
                    />
                    <OkIcon
                        style={{
                            marginTop: 15,
                            marginRight: 15,
                            cursor: "pointer",
                        }}
                        onClick={() => shareSocialNetworks("https://ok.ru/group/68979028066339")}
                    />
                    <AppStoreIcon
                        style={{
                            marginTop: 15,
                            marginRight: 15,
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            shareSocialNetworks(
                                "https://apps.apple.com/ru/app/%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0-%D0%B2-%D1%8F%D0%BA%D1%83%D1%82%D0%B8%D0%B8/id6444732583?l=en"
                            )
                        }
                    />
                    <GooglePlayIcon
                        style={{
                            marginTop: 15,
                            marginRight: 15,
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            shareSocialNetworks(
                                "https://play.google.com/store/apps/details?id=com.osher_tech.job_in_yakutia"
                            )
                        }
                    />
                </Box>
                <Box
                    sx={{
                        opacity: 0.15,
                        marginTop: 2,
                        marginBottom: 2,
                        width: "100%",
                        border: "1px solid #FFFFFF",
                    }}
                ></Box>
                <Typography
                    sx={{
                        color: "customColors.white",
                        fontWeight: 400,
                        opacity: 0.4,
                        fontSize: 14,
                    }}
                >
                    ГКУ РС(Я) ЦЗН РС(Я)
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
