import { useState } from "react";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import "./interactiveMap.css";
import mapData from "../../../mapData/geodata";
import { InteractiveMapSlice } from "../../../reducer/InteractiveMapSlice";

const InteractiveMap = () => {
    const { handleOpenInteractiveMapModal, getRegionData } =
        InteractiveMapSlice.actions;

    const [regionName, setRegionName] = useState("");
    const [regionId, setRegionId] = useState(0);

    const dispatch = useDispatch();

    const handleRegion = (item) => {
        setRegionId(item.ariaLabel);
        dispatch(getRegionData(item));
        dispatch(handleOpenInteractiveMapModal(true));
    };

    return (
        <div
            style={{
                width: "95%",
                height: "98%",
                padding: 5,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <Typography
                sx={{
                    fontSize: 20,
                    fontWeight: 500,
                    height: 30,
                    display: {
                        xs: "none",
                        md: "block",
                    },
                }}
                color="primary.main"
            >
                {regionName}
            </Typography>
            <svg
                width="100%"
                height="95%"
                viewBox="0 0 672 682"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="rsmap"
                onMouseLeave={() => setRegionName("")}
            >
                {mapData.map((item, index) => (
                    <path
                        key={index}
                        fillRule="evenodd"
                        data-redion-id={item.dataRegionId}
                        data-region-code={item.dataRegionCode}
                        className="region"
                        data-bs-toggle="tooltip"
                        title=""
                        clipRule="evenodd"
                        d={item.d}
                        style={{
                            fill:
                                regionName === item.ariaLabel &&
                                "#1b4e9b",
                        }}
                        fill="#6AB9E8"
                        data-bs-original-title={
                            item.dataBsOriginalTitle
                        }
                        aria-label={item.ariaLabel}
                        onMouseOver={() =>
                            setRegionName(item.ariaLabel)
                        }
                        onClick={() => handleRegion(item)}
                    ></path>
                ))}
            </svg>
        </div>
    );
};

export default InteractiveMap;
