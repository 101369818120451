const ROUTES = {
    HOME: "/",
    BANK_VACANCY: "/vacancy",
    NEWS_PAGE: "/news",
    TEST_PAGE: "/test",
    NEWS_DETAIL_PAGE: "/news/detail",
    EVENT_CALENDAR_PAGE: "/event_calendar",
    CITIZENS_SERVICE_PAGE: "/citizens",
    PROMO_BLOCKS_PAGE: "/promo_blocks",
    EMPLOYERS_PAGE: "/employers",
    EMPLOYERS_PAGE_DETAIL: "/employers/detail",
    CONTESTS_PAGE: "/contests",
    INTERACTIVE_MAP_PAGE: "/interactive_map",
    PARTNERS_PAGE: "/partners",
    FAQ_PAGE: "/faq",
    FILIAL_PAGE: "/filial",
    DOCUMENTS_PAGE: "/documents",
    PARTNERS_DETAIL_PAGE: "/partners/detail",
    CITIZENS_DETAIL_PAGE: "/citizens/detail",
    CONTESTS_DETAIL_PAGE: "/contests/detail",
    EVENT_CALENDAR_DETAIL_PAGE: "/event_calendar/detail",
    TERM_OF_USE_PAGE: "/term_of_use",
    ANTI_CORRUPTION_PAGE: "/anti_corruption",
    MINORS_EMPLOYMENT_PAGE: "/minors_employment",
};

export default ROUTES;
