import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const MenuItemWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    "&:hover": {
        "& .text": {
            color: "#1B4E9B",
        },
        "& .underline": {
            opacity: 1,
        },
        "& .menuItem": {
            backgroundColor: "#f5f5f5",
        },
    },
}));

const CustomMenuItem = ({
    children,
    path,
    onClick,
    color,
    type,
    fontSize,
    fontWeight,
    icon,
    ...props
}) => {
    const navigate = useNavigate();

    const navigation = () => {
        navigate(path);
    };

    return (
        <MenuItemWrapper {...props}>
            <Box
                onClick={path ? navigation : onClick}
                sx={{
                    pl: 2,
                    pr: 2,
                    pt: 1,
                    pb: 1,
                    transition: "all 500ms ease",
                    display: "flex",
                    alignItems: "center",
                    gap: 1.5,
                    cursor: "pointer",
                }}
                className="menuItem"
            >
                {icon}
                <Typography
                    sx={{
                        color: color,
                        fontWeight: fontWeight ? fontWeight : 500,
                        fontSize: fontSize ? fontSize : 16,
                        transition: "all 500ms ease",
                    }}
                    className="text"
                >
                    {children}
                </Typography>
            </Box>

            {type === "header" && (
                <Box
                    className="underline"
                    sx={{
                        height: 2,
                        opacity: 0,
                        background: "#1B4E9B",
                        transition: "all 500ms ease",
                    }}
                ></Box>
            )}
        </MenuItemWrapper>
    );
};

export default CustomMenuItem;
