import * as React from "react";

const GhostPurpleSvg = (props) => (
    <svg
        width={196}
        height={287}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g opacity={0.05}>
            <path
                d="M76.255 3.506v71.009c0 2.722 2.625 4.403 4.687 3l52.143-35.505c1.998-1.36 1.998-4.639 0-6L80.942.507c-2.062-1.403-4.687.277-4.687 3Z"
                fill="#1B4E9B"
            />
            <path
                d="M194.502 77.706 142.359 42.2c-2.062-1.403-4.687.277-4.687 3l-.244 71.009c0 2.723-2.625 4.403-4.686 2.999L-41.556.553c-2.062-1.405-4.688.275-4.688 2.999l-.118 237.744c-.002 2.722 2.623 4.405 4.685 3.003L-4.56 219.06l-.01.028 14.841-10.106c2.062-1.403 4.687.277 4.687 3v71.009c0 .143-.011.282-.025.419.254 2.47 2.709 3.91 4.662 2.58l52.142-35.504c1.998-1.361 1.998-4.639 0-5.999l-48.643-33.122.003-.033-3.734-2.542c-1.997-1.36-1.997-4.638 0-5.999l52.143-35.504c.142-.096.286-.175.431-.243l122.565-83.34c1.997-1.36 1.997-4.638 0-5.998Z"
                fill="#6AB9E8"
            />
        </g>
    </svg>
);

export default GhostPurpleSvg;
