import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// function openWindowWithPost(url) {

//         const form = document.createElement("form")
//         form.target = "_blank"
//         form.method = "GET"
//         form.action = url
//         form.style.display = "none"

//         document.body.appendChild(form)
//         form.submit()

//         document.body.removeChild(form)
//     }

const DocumentsAccardion = ({ name, description, documents }) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    height: 72,
                }}
            >
                <Typography
                    sx={{
                        color: "#1B4E9B",
                        fontSize: 16,
                        fontWeight: 500,
                    }}
                >
                    {name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                }}
            >
                {documents?.map((item, index) => (
                    <Typography
                        key={index}
                        style={{
                            color: "#1B4E9B",
                            fontSize: 14,
                            fontWeight: 500,
                            textDecoration: "none",
                            cursor: "pointer",
                            wordWrap: "break-word",
                            marginRight: 5,
                            transition: "all 500ms ease",
                        }}
                        onClick={() => window.open(item.file)}
                    >
                        {index + 1} {item.name}
                    </Typography>
                ))}
            </AccordionDetails>
        </Accordion>
    );
};

export default DocumentsAccardion;
