import { Button } from "@mui/material";
import { useDispatch } from "react-redux";

import { QuestionMark } from "../../../static/icons";
import { ModalsSlice } from "../../../reducer/ModalsSlice";

const FixedButton = () => {
    const { handleGlobalQuestionModalOpen } = ModalsSlice.actions;

    const dispatch = useDispatch();

    const openGlobalQuestionModal = () => {
        dispatch(handleGlobalQuestionModalOpen(true));
    };

    return (
        <Button
            variant="contained"
            color="secondary"
            sx={{
                padding: 2,
                position: "fixed",
                zIndex: 10,
                bottom: {
                    xs: 20,
                    md: 57,
                },
                right: {
                    xs: 20,
                    md: 57,
                },
                width: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 4,
                boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.4)",
                cursor: "pointer",
            }}
            onClick={openGlobalQuestionModal}
        >
            <QuestionMark />
        </Button>
    );
};

export default FixedButton;
