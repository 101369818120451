import { Box, Menu } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CustomMenuItem } from "../..";

const DropDownMenu = ({ dropDownList }) => {
    const navigate = useNavigate();

    const navigation = (path) => {
        navigate(path);
    };

    return (
        <Box>
            {dropDownList.map((item, index) => (
                <CustomMenuItem
                    key={index}
                    color="primary.main"
                    onClick={() => navigation(item.path)}
                >
                    {item.label}
                </CustomMenuItem>
            ))}
        </Box>
    );
};

export default DropDownMenu;
