import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CustomMenuItem } from "../..";
import { MobileDrawerSlice } from "../../../reducer/MobileDrawerSlice";

const MobileDrawerSideMenu = () => {
    const {
        handleShowMobileDrawerSideMenu,
        getSideMenuList,
        handleShowDrawer,
    } = MobileDrawerSlice.actions;
    const { showMobileDrawerSideMenu, sideMenuList } = useSelector(
        (state) => state.MobileDrawerSlice
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const closeSideMenu = () => {
        dispatch(handleShowMobileDrawerSideMenu(false));
        dispatch(getSideMenuList([]));
    };

    const navigation = (path) => {
        dispatch(handleShowDrawer(false));
        navigate(path);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                padding: 2,
                opacity: showMobileDrawerSideMenu ? 1 : 0,
                position: showMobileDrawerSideMenu
                    ? "relative"
                    : "absolute",

                zIndex: showMobileDrawerSideMenu ? 10 : 0,
                overflow: "hidden",
                transition: "all 500ms ease",
                background: "white",
            }}
        >
            {showMobileDrawerSideMenu && (
                <CustomMenuItem
                    color="primary.main"
                    onClick={closeSideMenu}
                >
                    Назад
                </CustomMenuItem>
            )}
            {sideMenuList.map((item, index) => (
                <CustomMenuItem
                    key={index}
                    color="primary.main"
                    onClick={() => navigation(item.path)}
                >
                    {item.label}
                </CustomMenuItem>
            ))}
        </Box>
    );
};

export default MobileDrawerSideMenu;
