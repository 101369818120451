import { Container } from "@mui/material";

import {
    FirstScreen,
    NewsSliderWrapper,
    TestsAndSurveys,
    InteractiveMapScreen,
    PartnersSliderWrapper,
    UsefulLinksScreen,
    FeedBackScreen,
} from "./homeComponents";
import HomeTools from "./HomeTools";
import {
    InteractionMapModal,
    ResultTestModal,
} from "../../components";

const HomePage = () => {
    const {
        generalLoading,
        newsData,
        partnersData,
        promoBlocksList,
        testList,
        vacancyCount,
    } = HomeTools();

    return (
        <div>
            <InteractionMapModal />
            <ResultTestModal />
            <FirstScreen
                vacancyCount={vacancyCount}
                promoBlocksList={promoBlocksList}
                generalLoading={generalLoading}
            />
            <Container>
                <NewsSliderWrapper
                    generalLoading={generalLoading}
                    newsData={newsData}
                />
                {/*
                <TestsAndSurveys
                    testList={testList}
                    generalLoading={generalLoading}
                />
                */}
                <InteractiveMapScreen />
                <PartnersSliderWrapper
                    partnersData={partnersData}
                    generalLoading={generalLoading}
                />
            </Container>
            <UsefulLinksScreen />
            <FeedBackScreen />
        </div>
    );
};

export default HomePage;
