import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NavigateButtonDesktop = ({ buttonLabel, navigation }) => {
    return (
        <Button
            variant="outlined"
            sx={{
                borderColor: "primary.light",
                color: "primary.light",
                display: {
                    xs: "none",
                    sm: "block",
                },
            }}
            onClick={navigation}
        >
            {buttonLabel}
        </Button>
    );
};

const NavigateButtonMobile = ({ buttonLabel, navigation }) => {
    return (
        <Button
            variant="outlined"
            sx={{
                borderColor: "primary.light",
                color: "primary.light",
                display: {
                    xs: "block",
                    sm: "none",
                },
                mt: 4,
                width: "100%",
            }}
            onClick={navigation}
        >
            {buttonLabel}
        </Button>
    );
};

const HomeScreensLayout = ({ children, path, title, buttonLabel, ...props }) => {
    const navigate = useNavigate();

    const navigation = () => {
        navigate(path);
    };

    return (
        <Box
            sx={{
                mt: {
                    md: 19,
                    xs: 8,
                },
            }}
            {...props}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: {
                        md: 2,
                        xs: 0,
                    },
                }}
            >
                <Typography
                    sx={{
                        fontWeight: {
                            md: 500,
                            xs: 600,
                        },
                        fontSize: {
                            xs: 18,
                            md: 28,
                        },

                        textTransform: {
                            md: "none",
                            xs: "uppercase",
                        },
                    }}
                >
                    {title}
                </Typography>
                {buttonLabel && <NavigateButtonDesktop buttonLabel={buttonLabel} navigation={navigation} />}
            </Box>
            {children}
            {buttonLabel && <NavigateButtonMobile buttonLabel={buttonLabel} navigation={navigation} />}
        </Box>
    );
};

export default HomeScreensLayout;
