import { Box } from "@mui/material";
import { useForm } from "react-hook-form";

import OneChoise from "./questions/OneChoise";
import MultpleChoise from "./questions/MultpleChoise";
import TestModalTools from "./TestModalTools";

const TestModalForm = ({ testIdData, id }) => {
    const { register, handleSubmit } = useForm();

    const { onFinish } = TestModalTools({
        name: testIdData.name,
        id: id,
    });

    return (
        <form onSubmit={handleSubmit(onFinish)} id="testForm">
            {testIdData.questions.map((item, index) => (
                <Box key={index}>
                    {item.question_type === "ONE_CHOICE" && (
                        <OneChoise
                            index={index}
                            register={register}
                            name={item.description}
                            variants={item.variants}
                            questionId={item.id}
                        />
                    )}
                    {item.question_type === "MULTIPLE_CHOICE" && (
                        <MultpleChoise
                            index={index}
                            register={register}
                            name={item.description}
                            variants={item.variants}
                            questionId={item.id}
                        />
                    )}
                </Box>
            ))}
        </form>
    );
};

export default TestModalForm;
