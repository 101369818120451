import { NewsSlider } from "../../../../components";
import ROUTES from "../../../../routes";
import { HomeScreensLayout } from "../../../../layouts";

const NewsSliderWrapper = ({ newsData, generalLoading }) => {
    return (
        <HomeScreensLayout
            title="Новостная лента"
            buttonLabel="Новостная лента"
            path={ROUTES.NEWS_PAGE}
            sx={{
                mt: {
                    md: 13,
                    xs: 7.4,
                },
            }}
        >
            <NewsSlider
                newsData={newsData}
                generalLoading={generalLoading}
            />
        </HomeScreensLayout>
    );
};

export default NewsSliderWrapper;
