import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    Paper,
    Typography,
    Box,
} from "@mui/material";
import geoData from "../../mapData/geodata";

const FilialTable = ({ filialData }) => {
    const sortFilialData = geoData.sort((a, b) => a.dataBsOriginalTitle.localeCompare(b.dataBsOriginalTitle));

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>№</TableCell>
                        <TableCell align="left">
                            <Typography
                                sx={{
                                    color: "customColors.dark",
                                    fontWeight: 500,
                                    fontSize: 14,
                                }}
                            >
                                Наименование центра занятости населения
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography
                                sx={{
                                    color: "customColors.dark",
                                    fontWeight: 500,
                                    fontSize: 14,
                                }}
                            >
                                Горячие линии
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography
                                sx={{
                                    color: "customColors.dark",
                                    fontWeight: 500,
                                    fontSize: 14,
                                }}
                            >
                                Адреса электронной почты
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography
                                sx={{
                                    color: "customColors.dark",

                                    fontWeight: 500,
                                    fontSize: 14,
                                }}
                            >
                                Сотовые номера
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortFilialData.map((row, index) => {
                        if (row.dataBsOriginalTitle !== sortFilialData[index - 1]?.dataBsOriginalTitle) {
                            return (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                            }}
                                        >
                                            {row.num}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                            }}
                                        >
                                            {row.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" width={200}>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                            }}
                                        >
                                            {row.hotline}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                            }}
                                        >
                                            {row.mail}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" width={200}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            {row.phones.map((item, index) => (
                                                <Typography
                                                    key={index}
                                                    sx={{
                                                        fontSize: 14,
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    {item}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            );
                        }
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default FilialTable;
