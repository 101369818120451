import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";

import mapData from "../../../mapData/geodata";
import { InteractiveMapSlice } from "../../../reducer/InteractiveMapSlice";

const RegionItem = styled(Typography)(() => ({
    fontSize: 14,
    fontWeight: 500,
    marginTop: 8,
    cursor: "pointer",
    transition: "all 500ms ease",
    "&:hover": {
        color: "#E54D2B",
    },
}));

const InteractiveMapSideBar = () => {
    const { handleOpenInteractiveMapModal, getRegionData } =
        InteractiveMapSlice.actions;

    const dispatch = useDispatch();

    const openInteractiveMapModal = (item) => {
        dispatch(handleOpenInteractiveMapModal(true));
        dispatch(getRegionData(item));
    };

    const sortMapData = mapData.sort((a, b) =>
        a.dataBsOriginalTitle.localeCompare(b.dataBsOriginalTitle)
    );

    return (
        <Box
            sx={{
                bgcolor: "white",
                height: "100%",
                borderRadius: 4,
                filter: "drop-shadow(1px 2px 8px rgba(0, 0, 0, 0.15))",
            }}
        >
            <Box sx={{ padding: 2, height: "100%" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        height: "100%",
                        mt: "-10px",
                    }}
                >
                    {sortMapData.map((item, index) => {
                        if (
                            item.dataRegionCode !==
                            mapData[index - 1]?.dataRegionCode
                        ) {
                            return (
                                <Box key={index}>
                                    <RegionItem
                                        color="primary.main"
                                        onClick={() =>
                                            openInteractiveMapModal(
                                                item
                                            )
                                        }
                                    >
                                        {item.dataBsOriginalTitle}
                                    </RegionItem>
                                </Box>
                            );
                        }
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default InteractiveMapSideBar;
