import { useParams } from "react-router-dom";

import { CommonLoading, PageTitle } from "../../components";
import { useGetContestsDataIdQuery } from "../../services/ContestsService";
import { ContestsDetailBody } from "./contestsComponents";

const ContestsDetailPage = () => {
    const params = useParams();

    const { data: contestDataId, isLoading: isContestDataIdLoading } =
        useGetContestsDataIdQuery({ id: params.id });

    if (isContestDataIdLoading) {
        return <CommonLoading />;
    }

    return (
        <div>
            <PageTitle>{contestDataId.name}</PageTitle>
            <ContestsDetailBody contestDataId={contestDataId} />
        </div>
    );
};

export default ContestsDetailPage;
