import { Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { NewsBody } from "./newsComponents";
import { useGetNewsListQuery } from "../../services/NewsService";
import { usePagination } from "../../hooks";
import { MuiPagination, CommonLoading } from "../../components";
import ROUTES from "../../routes";

const NewsPage = () => {
    const { currentPage, handlePage, pageSize } = usePagination();

    const { data: newsData, isLoading: isGetNewsListLoading } =
        useGetNewsListQuery({ currentPage: currentPage });

    const navigate = useNavigate();

    const navigation = () => {
        navigate(ROUTES.EVENT_CALENDAR_PAGE);
    };

    if (isGetNewsListLoading) {
        return <CommonLoading />;
    }

    return (
        <Box sx={{ mb: 2 }}>
            <Button variant="outlined" onClick={navigation}>
                Календарь мероприятий
            </Button>
            <NewsBody newsList={newsData?.results} />
            <MuiPagination
                pageSize={pageSize}
                totalPage={newsData.count}
                onChange={handlePage}
                currentPage={currentPage}
            />
        </Box>
    );
};

export default NewsPage;
