import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    newsList: [],
    generalLoading: false,
};

export const HomeDataSlice = createSlice({
    name: "HomeDataSlice",
    initialState,
    reducers: {
        getNewsList(state, action) {
            state.newsList = action.payload;
        },
        activateGeneralLaoding(state, action) {
            state.generalLoading = action.paylaod;
        },
    },
});

export default HomeDataSlice.reducer;
