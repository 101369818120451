import { Grid } from "@mui/material";

import { TestCard } from "../../../components";

const Body = ({ data }) => {
    return (
        <div>
            <Grid container spacing={3}>
                {data.results.map((item, index) => (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        key={index}
                    >
                        <TestCard
                            type={"Тестирование"}
                            img={"/img/Frame1721.svg"}
                            title={item.name}
                            count={item.questions.length}
                            id={item.id}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default Body;
