const DescriptionScreen = ({ data }) => {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: data?.text,
            }}
        ></div>
    );
};

export default DescriptionScreen;
