import { api } from "./api";

export const partnersService = api.injectEndpoints({
    endpoints: (build) => ({
        getPartnersData: build.query({
            query: ({ currentPage, pageSize }) =>
                `partner/user/?page=${currentPage}&pageSize=${pageSize}`,
        }),
        getPartnersDataId: build.query({
            query: ({ id }) => `partner/user/${id}/`,
        }),
    }),
});

export const { useGetPartnersDataQuery, useGetPartnersDataIdQuery } =
    partnersService;
