import moment from "moment";
import { Box, Typography } from "@mui/material";

import ROUTES from "../../../../../routes";
import { CommonCard, CommonLoading } from "../../../../../components";

const EventList = ({ isEventCalendarDataFetching, data, date }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
                sx={{ fontSize: 20, fontWeight: 500 }}
                color="primary.main"
            >
                Список мероприятий
            </Typography>
            {isEventCalendarDataFetching ? (
                <CommonLoading />
            ) : data?.results.length > 0 ? (
                data?.results.map((item, index) => (
                    <CommonCard
                        key={index}
                        short_desc={item.short_desc}
                        image={item.image}
                        name={item.name}
                        pub_date={item.pub_date}
                        id={item.id}
                        route={ROUTES.EVENT_CALENDAR_DETAIL_PAGE}
                    />
                ))
            ) : (
                <Typography sx={{ mt: 1 }} color="primary.main">
                    На{" "}
                    {moment(date[0].startDate).format("DD.MM.YYYY")}{" "}
                    по {moment(date[0].endDate).format("DD.MM.YYYY")}{" "}
                    нет мероприятий
                </Typography>
            )}
        </Box>
    );
};

export default EventList;
