import { Box } from "@mui/material";

import { PartnersBody } from "./partnersComponents";
import { useGetPartnersDataQuery } from "../../services/PartnersService";
import { CommonLoading, MuiPagination } from "../../components";
import { usePagination } from "../../hooks";

const PartnersPage = () => {
    const { currentPage, handlePage, pageSize } = usePagination();

    const { data: partnersData, isLoading: isPartnersDataLoading } =
        useGetPartnersDataQuery({
            currentPage: currentPage,
            pageSize: pageSize,
        });

    if (isPartnersDataLoading) {
        return <CommonLoading />;
    }

    return (
        <Box>
            <PartnersBody partnersData={partnersData} />
            <MuiPagination
                pageSize={pageSize}
                totalPage={partnersData.count}
                onChange={handlePage}
                currentPage={currentPage}
            />
        </Box>
    );
};

export default PartnersPage;
