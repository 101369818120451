import { createChatBotMessage } from "react-chatbot-kit";
import { Typography } from "@mui/material";

import { UserIcon } from "../../static/icons";

const config = {
    initialMessages: [
        createChatBotMessage(
            `Здравствуйте, пожалуйста, введите "Помощь", чтобы начать разговор`
        ),
    ],
    customComponents: {
        header: () => (
            <div
                style={{
                    padding: "8px 24px",
                    borderRadius: "3px",
                    background: "#1B4E9B",
                    height: {
                        sm: 58,
                        xs: 40,
                    },
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <UserIcon />
                <Typography
                    sx={{
                        color: "white",
                        fontWeight: 600,
                        fontSize: {
                            xs: 14,
                            sm: 18,
                        },
                        marginLeft: 1,
                    }}
                >
                    Чат-бот Центра занятости
                </Typography>
            </div>
        ),
        botAvatar: (props) => <UserIcon />,
        userAvatar: (props) => <UserIcon />,
        // userChatMessage: (props) => (
        //     <Box
        //         sx={{
        //             background: "#CFEBFF",
        //             width: 300,
        //             minHeight: 80,
        //             borderRadius: 16,
        //         }}
        //     ></Box>
        // ),
    },
};

export default config;
