import { Box } from "@mui/material";

import { CommonCard } from "../../../../components";
import ROUTES from "../../../../routes";

const ContestsListBody = ({ contestData }) => {
    return (
        <Box>
            {contestData.results.map((item, index) => (
                <CommonCard
                    key={index}
                    short_desc={item.short_desc}
                    image={item.image}
                    name={item.name}
                    pub_date={item.pub_date}
                    id={item.id}
                    route={ROUTES.CONTESTS_DETAIL_PAGE}
                />
            ))}
        </Box>
    );
};

export default ContestsListBody;
