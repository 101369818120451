import {
    Container,
    Box,
    Typography,
    Grid,
    Button,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

import {
    VacancyInfo,
    PromoBlockSlider,
    VacancyCountSkeletonLoading,
} from "../../../../components";
import { GhostBlueSvg } from "../../../../static/img";
import ROUTES from "../../../../routes";

const WrapperContainer = styled(Box)(({ theme }) => ({
    overflow: "hidden",
    position: "relative",
    background: "white",
    [theme.breakpoints.down("md")]: {
        paddingTop: 30,
    },
}));

const CustomContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    minHeight: 550,
    paddingTop: 76,
    paddingBottom: 40,
}));

const Img = styled("img")(({ theme }) => ({
    height: 98,
    width: 98,
    [theme.breakpoints.down("lg")]: {
        display: "none",
    },
}));

const GhostImg = styled(GhostBlueSvg)(({ theme }) => ({
    height: 260,
    width: 240,
    position: "absolute",
    left: "46%",
    top: "19%",
}));

const FirstScreen = ({
    promoBlocksList,
    generalLoading,
    vacancyCount,
}) => {
    const navigate = useNavigate();

    const navigationVacancy = () => {
        navigate(ROUTES.BANK_VACANCY);
    };

    const navigationEventCalendar = () => {
        navigate(ROUTES.EVENT_CALENDAR_PAGE);
    };

    return (
        <WrapperContainer>
            <GhostImg />
            <CustomContainer>
                <Grid
                    container
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                    spacing={2}
                >
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Img src="/img/iconczn.svg" />
                            {generalLoading() ? (
                                <VacancyCountSkeletonLoading />
                            ) : (
                                <VacancyInfo
                                    num={vacancyCount}
                                    label="Свободных вакансий в Республике Саха (Якутия)"
                                    sx={{
                                        ml: {
                                            md: 5,
                                            xs: 0,
                                        },
                                    }}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                width: {
                                    lg: "90%",
                                    xs: "100%",
                                },
                            }}
                        >
                            <Typography
                                color="primary.main"
                                sx={{
                                    fontSize: {
                                        md: 36,
                                        xs: 24,
                                    },
                                    fontWeight: 500,
                                    mt: {
                                        md: 8,
                                        xs: 3,
                                    },
                                }}
                            >
                                Найдите работу мечты в любой точке
                                Якутии
                            </Typography>
                        </Box>
                        <Typography
                            color="primary.lightDart"
                            sx={{
                                fontSize: {
                                    md: 20,
                                    xs: 16,
                                },
                                fontWeight: { md: 400, xs: 500 },
                                mt: 2,
                            }}
                        >
                            Портал для работодателей и соискателей
                            Якутии
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xl={5.5}
                        lg={5.5}
                        md={6}
                        sm={12}
                        xs={12}
                    >
                        <PromoBlockSlider
                            promoBlocksList={promoBlocksList}
                            generalLoading={generalLoading}
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        mt: 5.6,
                        // display: {
                        //     md: "flex",

                        //     xs: "none",
                        // },
                    }}
                >
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={navigationVacancy}
                        sx={{
                            mt: {
                                xs: 1,
                                md: 0,
                            },
                            mb: {
                                xs: 2,
                                md: 0,
                            },
                            width: {
                                xs: "100%",
                                md: "max-content",
                            },
                        }}
                    >
                        Поиск работы
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                            ml: {
                                xs: 0,
                                md: 2,
                            },
                            width: {
                                xs: "100%",
                                md: "max-content",
                            },
                        }}
                        onClick={navigationEventCalendar}
                    >
                        Календарь мероприятий
                    </Button>
                </Box>
            </CustomContainer>
        </WrapperContainer>
    );
};

export default FirstScreen;
