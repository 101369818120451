import { api } from "./api";

export const vacancyService = api.injectEndpoints({
    endpoints: (build) => ({
        getVacancyCount: build.query({
            query: () => `/close/length/`,
        }),
    }),
});

export const { useGetVacancyCountQuery } = vacancyService;
