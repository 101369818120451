import { Box } from "@mui/material";
import {
    VKShareButton,
    VKIcon,
    TelegramIcon,
    TelegramShareButton,
    ViberShareButton,
    WhatsappShareButton,
    OKShareButton,
    OKIcon,
    ViberIcon,
    WhatsappIcon,
} from "react-share";

const ShareScreen = ({ data }) => {
    const shareUrl = `https://xn--80aacf4aam3apxdl8m.xn--p1ai/news/detail/${data.id}`;

    return (
        <Box
            sx={{
                display: "flex",
                gap: 2,
                width: "100%",
                justifyContent: "flex-end",
                mt: 1,
            }}
        >
            <VKShareButton url={shareUrl}>
                <VKIcon size={24} style={{ borderRadius: 20 }} />
            </VKShareButton>
            <TelegramShareButton url={shareUrl}>
                <TelegramIcon
                    size={24}
                    style={{ borderRadius: 20 }}
                />
            </TelegramShareButton>
            <ViberShareButton url={shareUrl}>
                <ViberIcon size={24} style={{ borderRadius: 20 }} />
            </ViberShareButton>
            <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon
                    size={24}
                    style={{ borderRadius: 20 }}
                />
            </WhatsappShareButton>
            <OKShareButton url={shareUrl}>
                <OKIcon size={24} style={{ borderRadius: 20 }} />
            </OKShareButton>
        </Box>
    );
};

export default ShareScreen;
