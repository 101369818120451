import { Box } from "@mui/material";
import { styled } from "@mui/system";
import ReactPlayer from "react-player";

const WrapperVideoPlayer = styled(Box)(({ theme }) => ({
    height: 700,
    [theme.breakpoints.down("sm")]: {
        height: 400,
    },
}));

const VideoPlayer = ({ url, ...props }) => {
    return (
        <WrapperVideoPlayer {...props}>
            <ReactPlayer
                url={url}
                controls={true}
                playing={false}
                width="100%"
                height="100%"
            />
        </WrapperVideoPlayer>
    );
};

export default VideoPlayer;
