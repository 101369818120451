import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { usePostTestMutation } from "../../../services/TestService";
import { ModalsSlice } from "../../../reducer/ModalsSlice";

const TestModalTools = ({ id, name }) => {
    const [postTest, { isLoading: isPostTestLoading }] = usePostTestMutation();

    const {
        handleTestModalOpen,
        handleResultTestModalOpen,
        handleResult,
        handleScore,
    } = ModalsSlice.actions;

    const dispatch = useDispatch();

    const onFinish = (data) => {
        const postData = data.answers.map((item) => {
            if (item.multiple_choice) {
                return {
                    q_id: parseInt(item.q_id),
                    multiple_choice: item.multiple_choice
                        .filter((item) => {
                            if (item.a_id !== false) {
                                return item.a_id;
                            }
                        })
                        .map((item) => {
                            return {
                                a_id: parseInt(item.a_id),
                            };
                        }),
                };
            }
            return {
                q_id: parseInt(item.q_id),
                a_id: parseInt(item.a_id),
            };
        });
        console.log(postData);
        postTest({
            body: {
                name: name,
                answers: postData,
            },
            id: id,
        }).then((res) => {
            if (res.data) {
                toast.success("Вы успешно завершили тест");
                console.log(res.data);
                dispatch(handleTestModalOpen(false));
                dispatch(handleResultTestModalOpen(true));
                dispatch(handleResult(res.data.response));
                dispatch(handleScore(res.data.score));
            } else {
                toast.error("Во время прохождения теста, что то пошло не так");
            }
        });
    };

    return { onFinish, isPostTestLoading };
};

export default TestModalTools;
