import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

import { PageTitle } from "../../../components";
import informationList from "../informationList";

const Root = styled(Box)(() => ({
    background: "white",
    padding: 15,
}));

const StaticInformation = () => {
    return (
        <Root>
            <PageTitle>
                Государственное казенное учреждение Республики Саха
                (Якутия) «Центр занятости населения Республики Саха
                (Якутия)»
            </PageTitle>
            <Box>
                {informationList.map((item, index) => (
                    <Typography
                        sx={{
                            color: "#343434",
                            fontWeight: 700,
                            fontSize: 16,
                            mt: 2,
                        }}
                        key={index}
                    >
                        {item.label}
                        <span
                            style={{ marginLeft: 5, fontWeight: 500 }}
                        >
                            {" "}
                            {item.value}
                        </span>
                    </Typography>
                ))}
            </Box>
        </Root>
    );
};

export default StaticInformation;
