import { Skeleton } from "@mui/material";

const PromoSliderImgSkeletonLoading = () => {
    return (
        <Skeleton
            variant="rounded"
            sx={{
                width: "100%",
                height: {
                    md: 300,
                    xs: 200,
                },
            }}
        />
    );
};

export default PromoSliderImgSkeletonLoading;
