import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showChatBot: false,
};

export const ChatBotSlice = createSlice({
    name: "ChatBotSlice",
    initialState,
    reducers: {
        handleShowChatBot(state, action) {
            state.showChatBot = action.payload;
        },
    },
});

export default ChatBotSlice.reducer;
