import * as React from "react";

const GhostBlueSvg = (props) => (
    <svg
        width={240}
        height={260}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g opacity={0.1} fill="#6AB9E8">
            <path d="M181.471 154.584v64.478c0 2.473 2.593 3.998 4.628 2.724l51.497-32.239c1.973-1.236 1.973-4.212 0-5.448l-51.497-32.239c-2.035-1.274-4.628.251-4.628 2.724ZM0 116.74v64.478c0 2.473 2.593 3.998 4.629 2.724l51.497-32.239c1.973-1.236 1.973-4.212 0-5.448L4.629 114.016C2.593 112.742 0 114.268 0 116.74Z" />
            <path d="M237.521 108.492 65.121.46c-2.036-1.275-4.63.25-4.63 2.723L60.37 210.6v8.343c0 2.472-2.594 3.998-4.63 2.724l-51.496-32.24c-.017-.01-.031-.023-.048-.034-1.952-.832-4.197.646-4.197 2.946v64.479c0 2.472 2.593 3.998 4.629 2.723l232.89-145.601c1.974-1.234 1.975-4.211.002-5.447Z" />
        </g>
    </svg>
);

export default GhostBlueSvg;
