import ROUTES from "../../routes";

const list = [
    {
        title: "Гражданам",
        values: [
            {
                label: "Поиск работы",
                path: ROUTES.PARTNERS_PAGE,
            },
            {
                label: "Услуги гражданам",
                path: ROUTES.CITIZENS_SERVICE_PAGE,
            },
            {
                label: "Новостная лента",
                path: ROUTES.NEWS_PAGE,
            },
            {
                label: "Календарь мероприятий",
                path: ROUTES.EVENT_CALENDAR_PAGE,
            },
            {
                label: "Тестирование и опросы",
                path: ROUTES.TEST_PAGE,
            },
        ],
    },
    {
        title: "Работодателям",
        values: [
            {
                label: "Услуги работодателям",
                path: ROUTES.EMPLOYERS_PAGE,
            },
            {
                label: "Конкурсы / Отборы",
                path: ROUTES.CONTESTS_PAGE,
            },
        ],
    },
    {
        title: "Информация",
        values: [
            {
                label: "Филиалы и представительства ЦЗН РС(Я)",
                path: ROUTES.FILIAL_PAGE,
            },
            {
                label: "Интерактивная карта ЦЗН",
                path: ROUTES.INTERACTIVE_MAP_PAGE,
            },
            {
                label: "Общая информация",
                path: ROUTES.DOCUMENTS_PAGE,
            },
            {
                label: "Партнеры ЦЗН",
                path: ROUTES.PARTNERS_PAGE,
            },
            {
                label: "Документы ГКУ РС(Я) ЦЗН РС(Я)",
                path: ROUTES.ANTI_CORRUPTION_PAGE,
            },
        ],
    },
    // {
    //     title: "Вопросы",
    //     values: [
    //         {
    //             label: "Чат-бот",
    //             path: ROUTES.EMPLOYERS_PAGE,
    //         },
    //         {
    //             label: "Вопросы / ответы",
    //             path: ROUTES.CONTESTS_PAGE,
    //         },
    //         {
    //             label: "Обратная связь",
    //             path: ROUTES.CONTESTS_PAGE,
    //         },
    //     ],
    // },
];

export default list;
