import { Box } from "@mui/material";

import { CommonLoading, MuiPagination } from "../../components";
import { useGetCitizensDataQuery } from "../../services/CitizensService";
import { CitizensListBody } from "./citizensComponents";
import { usePagination } from "../../hooks";

const CitizensListPage = () => {
    const { currentPage, handlePage, pageSize } = usePagination();

    const { data: citizensData, isLoading: isCitizensDataLoading } =
        useGetCitizensDataQuery({ currentPage: currentPage });

    if (isCitizensDataLoading) {
        return <CommonLoading />;
    }

    return (
        <Box sx={{ mb: 2 }}>
            <CitizensListBody citizensData={citizensData} />
            <MuiPagination
                pageSize={pageSize}
                totalPage={citizensData.count}
                onChange={handlePage}
                currentPage={currentPage}
            />
        </Box>
    );
};

export default CitizensListPage;
