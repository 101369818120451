import { Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";

import { PageTitle } from "../../../components";

const MainBody = () => {
    return (
        <Box
            sx={{
                bgcolor: "bg.main",
                overflow: "hidden",
                pb: {
                    md: 20,
                    xs: 11,
                },
                pt: {
                    md: 4.8,
                    xs: 11,
                },
                minHeight: 800,
            }}
        >
            <Container>
                <PageTitle />
                <Outlet />
            </Container>
        </Box>
    );
};

export default MainBody;
