import { api } from "./api";

export const eventCalendarService = api.injectEndpoints({
    endpoints: (build) => ({
        getEventCalendarData: build.query({
            query: ({ currentPage, dateStart, dateEnd }) =>
                `events/?page=${currentPage}&date_after=${dateStart}&date_before=${dateEnd}`,
        }),
        getEventCalendarFullData: build.query({
            query: () => `events/?page=1`,
        }),
        getEventCalendarDataId: build.query({
            query: ({ id }) => `events/${id}`,
        }),
    }),
});

export const {
    useGetEventCalendarDataIdQuery,
    useGetEventCalendarFullDataQuery,
    useGetEventCalendarDataQuery,
} = eventCalendarService;
