import {
    VideoPlayer,
    DescriptionScreen,
    IntervalDateScreen,
    DocumentScreen,
} from "../../../../components";

const ContestsDetailBody = ({ contestDataId }) => {
    return (
        <div>
            {contestDataId?.video && (
                <VideoPlayer url={contestDataId?.video} />
            )}
            <IntervalDateScreen data={contestDataId} />
            <DescriptionScreen data={contestDataId} />
            {contestDataId?.document && (
                <DocumentScreen data={contestDataId} />
            )}
        </div>
    );
};

export default ContestsDetailBody;
