import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
    Footer,
    DesktopHeader,
    MobileHeader,
} from "../../components";
import { HomeBody, MainBody } from "./components";
import ROUTES from "../../routes";

const MainLayout = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div>
            <DesktopHeader />
            <MobileHeader />
            {location.pathname === ROUTES.HOME ? (
                <HomeBody />
            ) : (
                <MainBody />
            )}
            <Footer />
        </div>
    );
};

export default MainLayout;
