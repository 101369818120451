/* eslint-disable no-undef */
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const Iframe = styled("iframe")(({ theme }) => ({
    height: "5000px",
    marginTop: "-900px",
    marginLeft: "-30px",
    width: "105%",
    [theme.breakpoints.down("lg")]: {
        marginTop: "-800px",
    },
    [theme.breakpoints.down("sm")]: {
        marginTop: "-940px",
        width: "100%",
        marginLeft: 0,
    },
    [theme.breakpoints.down(426)]: {
        marginTop: "-1000px",
    },
    [theme.breakpoints.down(406)]: {
        marginTop: "-1020px",
    },
    [theme.breakpoints.down(380)]: {
        marginTop: "-1100px",
    },
    [theme.breakpoints.down(364)]: {
        marginTop: "-1150px",
    },
    [theme.breakpoints.down(337)]: {
        marginTop: "-1200px",
    },
}));

const VacancyPage = () => {
    return (
        <Box>
            <Box
                sx={{
                    overflow: "hidden",
                    height: 1300,
                    width: "100%",
                }}
            >
                <Iframe
                    scrolling="no"
                    src="https://trudvsem.ru/opendata/widgets/widget-search"
                    width="100%"
                    frameBorder="0"
                    title={"asd"}
                ></Iframe>
            </Box>
        </Box>
    );
};

export default VacancyPage;
