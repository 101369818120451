import { Box } from "@mui/material";

import FaqBody from "./faqComponents/FaqBody";
import { useGetFaqDataQuery } from "../../services/FaqService";
import { CommonLoading } from "../../components";

const FaqPage = () => {
    const { data: faqData, isLoading: isFaqDataLoading } =
        useGetFaqDataQuery();

    if (isFaqDataLoading) {
        return <CommonLoading />;
    }

    return (
        <Box>
            <FaqBody faqList={faqData.results} />
        </Box>
    );
};

export default FaqPage;
