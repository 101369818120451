import ROUTES from "../../routes";

export const SwitchPageTitle = (pathname) => {
    switch (pathname) {
        case ROUTES.BANK_VACANCY:
            return "Поиск работы";
        case ROUTES.TEST_PAGE:
            return "Тесты и опросы граждан";
        case ROUTES.NEWS_PAGE:
            return "Новостная лента";
        case ROUTES.EVENT_CALENDAR_PAGE:
            return "Календарь мероприятий";
        case ROUTES.CITIZENS_SERVICE_PAGE:
            return "Услуги гражданам";
        case ROUTES.PROMO_BLOCKS_PAGE:
            return "Промо-блоки";
        case ROUTES.CONTESTS_PAGE:
            return "Конкурсы / отборы";
        case ROUTES.EMPLOYERS_PAGE:
            return "Услуги работодателям";
        case ROUTES.INTERACTIVE_MAP_PAGE:
            return "Интерактивная карта ЦЗН";
        case ROUTES.PARTNERS_PAGE:
            return "Партнеры";
        case ROUTES.FAQ_PAGE:
            return "Вопрос - ответ";
        case ROUTES.FILIAL_PAGE:
            return "Филиалы и представительства ЦЗН РС(Я)";
        case ROUTES.DOCUMENTS_PAGE:
            return "Общая информация";
        case ROUTES.TERM_OF_USE_PAGE:
            return "Пользовательское соглашение";
        case ROUTES.ANTI_CORRUPTION_PAGE:
            return "Документы ГКУ РС(Я) ЦЗН РС(Я)";
        default:
            new Error();
    }
};
