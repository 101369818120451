import { styled } from "@mui/system";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const ArrowBack = styled(Box)(({ theme }) => ({
    position: "absolute",
    zIndex: 10,
    left: "-50px",
    height: "100%",
}));

const SamplePrevArrow = (props) => {
    const { style, onClick } = props;
    return (
        <ArrowBack style={{ ...style }} onClick={onClick}>
            <Box
                style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <IconButton>
                    <ChevronLeftIcon />
                </IconButton>
            </Box>
        </ArrowBack>
    );
};

export default SamplePrevArrow;
